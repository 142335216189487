import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import './custom.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import ErrorPage from './components/General/error';
import Home from "./pages/General/home";
import Login from './pages/General/Login';
import CatalogoUsuarios from './pages/configuration/users/CatalogoUsuarios';
import Items from './pages/configuration/items/items';
import Vegetables from './pages/configuration/vegetables/vegetables';
import Cuts from './pages/configuration/cuts/cuts';
import Presentation from 'pages/configuration/presentations/presentation';
import Warehouse from 'pages/configuration/warehouse/warehouse';
import Inventories from 'pages/configuration/inventories/inventories';
import Vendors from 'pages/configuration/vendors/vendors';
import Transaction from 'pages/inventories/transaction/transaction';
import Itemspervendor from 'pages/inventories/Items per Vendor/itemspervendor';
import Clients from 'pages/configuration/clients/clients';
import InvoicesReception from 'pages/Customers/invoicesReception/invoicesReception';
import Kardex from 'pages/inventories/kardex/kardex';
import Pedidos from 'pages/Customers/Orders/pedidos';
import StockOnHands from 'pages/inventories/Reports/Stock on hands/stockOnHands';
import Purchase from 'pages/inventories/purchase/purchase';
import List_order_purchLayout from 'pages/inventories/purchase/list_orderpurchase/list_order_purchase';
import Complaints from 'pages/External/complaints/complaints';
import ClientsList from 'pages/configuration/clients/clientsList/clientsList';
import Branches from 'pages/configuration/clients/branches/branches';
import BranchesList from 'pages/configuration/clients/branches/branchesList';
import Associateitems from 'pages/configuration/associateitems/associateitems';
import AssociateItemsBranch from 'pages/configuration/associateitems/associateitemsbranch';

class App extends Component {
  render() {
    return (
      <Routes>
        {
          routes_paths.map((route_path, index) => (
            <Route key={index} path={route_path.path} element={route_path.element} />
          ))
        }
      </Routes>
    );
  }
}

const routes_paths = [
  { path: '*', element: (<ErrorPage />) },
  { path: '/', element: (<Login />) },
  { path: '/home', element: (<Home />) },
  { path: '/login', element: (<Login />) },
  { path: '/itemsbase', element: (<Vegetables />) },
  { path: '/cuts', element: (<Cuts />) },
  { path: '/items', element: (<Items />) },
  { path: '/presentations', element: (<Presentation />) },
  { path: '/employees', element: (<CatalogoUsuarios />) },
  { path: '/facturacliente', element: (<InvoicesReception />) },
  { path: '/clients/', element: (<Clients />) },
  { path: '/clients/:id', element: (<Clients />) },
  { path: '/clients/list', element: (<ClientsList />) },
  { path: '/warehouses', element: (<Warehouse />) },
  { path: '/inventories', element: (<Inventories />) },
  { path: '/vendors', element: (<Vendors />) },
  { path: '/vendors/items/list/:vendorId', element: (<Itemspervendor />) },
  { path: '/inventories/transactions', element: (<Transaction />) },
  { path: '/kardex', element: (<Kardex />) },
  { path: '/purchases', element: (<Purchase />) },
  { path: '/purchases/list', element: (<List_order_purchLayout />) },
  { path: '/stockonhands', element: (<StockOnHands />) },
  { path: '/clients/orders', element: (<Pedidos />) },
  { path: '/clients/complaints', element: (<Complaints />) },
  { path: '/clients/branches/:clientId/:branchId?', element: (<Branches />) },
  { path: '/clients/branches/list/:clientId', element: (<BranchesList />) },
  { path: '/associateitems', element: (<Associateitems />) },
  { path: '/associateitems/:clientId/branches', element: (<AssociateItemsBranch />) },
]

export default App;
