import Layout from 'components/General/layout';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import './inventories.css';
import Cookies from 'js-cookie';
import { UrlApi } from 'Global/Globals';
import { icono_editar, icono_cancelar, icono_buscar } from '../../../Assets/Images/images';
import { useNavigate } from 'react-router-dom';
import UseMessages from 'components/Mensajes/messages_section';
import { Delete, Save, ViewModel } from 'Data/Inventories/InventoriesData';


const Inventories = () => {
    const messages = UseMessages();
    const htmlElmentMessages = messages.messagesHTMLElement;
    const [viewModel, setViewModel] = useState(null);
    const [inventoryData, setInventoryData] = useState({ id: 0, name: '' });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setInventoryData((prevdata) => ({
            ...prevdata,
            [name]: value
        }));
    }

    const handleEditClick = (inventory) => {
        setInventoryData(inventory);
    }

    const handleDeleteClick = async (inventory) => {
        messages.showLoadingMessage();
        const ok = await Delete({ id: inventory.id, name: inventory.name });
        if (ok) window.location.reload();
        messages.hideLoadingMessage();
    }

    const handleSaveClick = async () => {
        messages.showLoadingMessage();
        if (inventoryData.inve_Name === '' || inventoryData.inve_Name === null) {
            messages.showErrorMessage('Debe llenar todos los campos antes. ');
            return;
        }

        const ok = await Save(inventoryData);
        if (ok) window.location.reload();
        messages.hideLoadingMessage();
    }

    useEffect(async () => {
        document.title = 'SIFFS - Inventarios';
        messages.showLoadingMessage();
        const _viewModel = await ViewModel();
        setViewModel(_viewModel);
        console.log(_viewModel);
        messages.hideLoadingMessage();
    }, [])


    return (
        <Layout>
            {htmlElmentMessages}
            <>
                <h4>Inventarios</h4>
                <p className='Containerdescripcion'>
                    Modulo para la gestión de inventarios disponibles en sistema.
                </p>
                <hr />
                <Row>
                    <Col lg={3}>
                        <Form className='inventories-Form'>
                            <Form.Label>ID</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='ID'
                                name='id'
                                value={inventoryData.id}
                                onChange={(e) => { handleInputChange(e); }}
                            />
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Nombre'
                                name='name'
                                value={inventoryData.name}
                                onChange={(e) => { handleInputChange(e); }}
                            />
                            <br />
                            <Button
                                variant='success'
                                onClick={() => {
                                    messages.showConfirmMessage(() => { return () => { handleSaveClick(); } })
                                }}
                            >
                                Guardar
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                variant='secondary'
                            >
                                Nuevo
                            </Button>
                        </Form>
                    </Col>
                    <Col className='inventories-table-container'>
                        <Form.Control type='text' placeholder='Buscar' className='SearchVegetable' />
                        <img
                            className='btn-Search'
                            src={icono_buscar}
                            width={25}
                            height={25}
                            alt='botton search'
                        />
                        <Table className='GenericTable text-center'>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    viewModel?.inventories?.map((inventory, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => { handleEditClick(inventory); }}
                                        >
                                            <td>{inventory.id}</td>
                                            <td>{inventory.name}</td>
                                            <td>
                                                <span>
                                                    <img
                                                        src={icono_editar}
                                                        alt='icon_edit'
                                                        onClick={() => { handleEditClick(inventory); }}
                                                    />
                                                    &nbsp;|&nbsp;
                                                    <img
                                                        src={icono_cancelar}
                                                        alt=' icon_delete'
                                                        onClick={() => {
                                                            messages.showConfirmMessage(() => { return () => { handleDeleteClick(inventory); } })
                                                        }}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default Inventories;