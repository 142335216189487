import { api_endpoints } from "Global/Globals";
import axios from "axios";
import cookie from "js-cookie";
const token = cookie.get('token');


export async function ViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_purchases_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        if (error.message) {
            console.log(error.message);
        } else {
            console.log(error);
        }
        return null
    }
}

export async function LViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_purchasesList_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        if (error.message) {
            console.log(error.message);
        } else {
            console.log(error);
        }
        return null
    }
}

export async function Save(purchaseOrder) {
    try {
        await axios.post(api_endpoints.save_purchase, purchaseOrder, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        if (error.message) { console.error(error.message); }
        else { console.error(error); }
        return false;
    }
}

