import Layout from 'components/General/layout';
import React, { useEffect, useState } from 'react';
import './list_order_purchase.css';
import { useNavigate } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import Cookies from 'js-cookie';
import { api_endpoints, tablaAArrayDeObjetos } from 'Global/Globals';
import UseMessages from 'components/Mensajes/messages_section';
import { detalles_icon, excel_icon, option_icon } from 'Assets/Images/images';
import { CSVLink } from "react-csv";
import { LViewModel } from 'Data/Purchase/purchaseData';


const List_order_purchase = () => {
    const [data_to_export, setDataToExport] = useState([]);
    const [show_details_OP, setShowDetailOP] = useState(false);
    const [selected_purchase_order_id, setPurchaseOrderId] = useState(0);
    const [viewModel, setViewModel] = useState(null);
    const messages = UseMessages();
    const messagesHTMLElement = messages.messagesHTMLElement;
    const navigate = useNavigate();

    const showDetailsOrder = (OP) => {
        setShowDetailOP(true);
        setPurchaseOrderId(OP);
    }

    useEffect(async () => {
        messages.showLoadingMessage();
        const _viewModel = await LViewModel();
        setViewModel(_viewModel);
        console.log(_viewModel);
        const data_array = tablaAArrayDeObjetos("list-order-purchase-table");
        setDataToExport(data_array);
        messages.hideLoadingMessage();
    }, [])


    return (
        <Layout>
            {messagesHTMLElement}
            {show_details_OP && <SectionDetailsOP purchaseOrder={selected_purchase_order_id} onClose={() => { setShowDetailOP(false); }} />}
            <h4>Ordenes de compra</h4>
            <p className='Containerdescripcion'>
                Para desplegar la lista de productos asociada, de doble click.
            </p>
            <hr />

            <Button variant='secondary' onClick={() => { navigate('/purchases'); }}>Crear nueva</Button>
            <hr />
            <div className='inline-block'>
                {
                    viewModel?.purchaseOrders && (
                        <CSVLink
                            data={data_to_export}
                            filename='order_purchase_list'
                            className='no-underline'
                        >
                            <Button variant='success my-3 d-block'>
                                <img src={excel_icon} width={20} height={20} className='d-inline-block mr-2' />
                                <span >Exportar</span>
                            </Button>
                        </CSVLink>
                    )
                }
            </div>
            <Table
                className='GenericTable overedflow'
                id='list-order-purchase-table'
                hover
                striped
            >
                <thead>
                    <tr>
                        <th></th>
                        <th>Numero</th>
                        <th>Estatus</th>
                        <th>Almacen</th>
                        <th>Inventario</th>
                        <th>Proveedor</th>
                        <th>Moneda</th>
                        <th>Fecha de entrega</th>
                        <th>Total</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        viewModel?.purchaseOrders?.map((OP) => (
                            <tr
                                key={OP.id}
                            >
                                <td onClick={() => { showDetailsOrder(OP); }}>
                                    <img src={detalles_icon} className='w-3.5' />
                                </td>
                                <td>{OP.id}</td>
                                <td>{OP.status.name}</td>
                                <td>{OP.warehouse.name}</td>
                                <td>{OP.inventory.name}</td>
                                <td>{OP.vendor.name}</td>
                                <td>{ }</td>
                                <td>{OP.estimateArrive.substring(0, 10)}</td>
                                <td>$ {OP.total.toFixed(2)}</td>
                                <td>
                                    <img src={option_icon} className='w-5' />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </Layout>
    );
}

const SectionDetailsOP = ({ purchaseOrder, onClose }) => {

    useEffect(() => {
    }, [])

    return (
        // background-container
        <div className='fixed flex w-screen h-screen top-0 left-0 justify-center items-center bg-stone-900/[0.7]'>
            {/* main-container */}
            {
                purchaseOrder && (
                    <div className='w-max h-max p-3 rounded w-max h-max bg-zinc-800 purchase-details-container border-1 border-gray-400'>
                        <div className='flex justify-between'>
                            <h4 className='containerdescripcion'>Productos</h4>
                            <span className='inline-block w-max py-1 px-2 rounded bg-red-600 hover:bg-red-800 mr-3 mb-2' onClick={onClose}>X</span>
                        </div>
                        <Table
                            className='GenericTable overedflow'
                            id='list-order-purchase-table'
                            hover
                            striped
                        >
                            <thead>
                                <tr>
                                    <th>Clave</th>
                                    <th>Producto</th>
                                    <th>Costo</th>
                                    <th>Cantidad</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {
                                    purchaseOrder?.items?.map(item => (
                                        <tr key={item.item.id}>
                                            <td>{item.item.id}</td>
                                            <td>{item.item.name}</td>
                                            <td>${item.cost.toFixed(2)}</td>
                                            <td>{item.quantity.toFixed(2)}</td>
                                            <td>${(item.cost * item.quantity).toFixed(2)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                )
            }
        </div>
    )
}

export default List_order_purchase;