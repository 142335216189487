import React, { useEffect, useState } from 'react';
import Layout from 'components/General/layout';
import './presentation.css';
import { Form, Row, Col, Table, Button } from 'react-bootstrap';
import { icono_cancelar, icono_editar, icono_buscar } from '../../../Assets/Images/images';
import UseMessages from 'components/Mensajes/messages_section';
import { Delete, Save, ViewModel } from 'Data/items/presentationData';
import { getTheme } from 'Data/Employees/employeesData';


const Presentation = () => {
    const messages = UseMessages();
    const htmlElementMessages = messages.messagesHTMLElement;
    const [viewModel, setviewModel] = useState(null);
    const [isWieghtDescriptionVisible, setIsWieghtDescriptionVisible] = useState(false);
    const [isPieceDescriptionVisible, setIsPieceDescriptionVisible] = useState(false);
    const [isDarkTheme, setIsDarkTheme] = useState(false);
    const [presentationData, setPresetationData] = useState({
        prese_Id: 0,
        prese_Name: '',
        prese_Weight: 0,
        prese_MeasUnit: 0,
        prese_Pieces: 0
    });

    const handleinputChange = (e) => {
        const { name, value } = e.target;

        setPresetationData((prevdata) => ({
            ...prevdata,
            [name]: value
        }))
    }

    const handleEditClick = (presentation) => {
        setPresetationData({
            prese_Id: presentation.id,
            prese_Name: presentation.name,
            prese_Weight: presentation.weight,
            prese_Pieces: presentation.unitsQuantity
        })
    }

    const handleDeleteClick = (presentation) => {
        messages.showLoadingMessage();
        const _presentation = JSON.stringify({
            prese_Id: presentation.prese_Id
        });
        Delete(_presentation);
        messages.hideLoadingMessage();
    }

    const handleonSubmit = async () => {
        if (
            (presentationData.prese_Name === '' || presentationData.prese_Name === null) ||
            (presentationData.prese_Weight === null || presentationData.prese_Weight < 0) ||
            (presentationData.prese_Pieces === null || presentationData.prese_Pieces < 0) ||
            (presentationData.prese_MeasUnit === null)
        ) {
            messages.showErrorMessage('Llene la información requerida.');
            return;
        }

        messages.showLoadingMessage();
        const presentation = JSON.stringify({
            id: Number(presentationData.prese_Id),
            name: presentationData.prese_Name,
            weight: Number(presentationData.prese_Weight ?? 0),
            unitsQuantity: Number(presentationData.prese_Pieces ?? 0),
        });
        console.log(presentation);
        const isOk = await Save(presentation);
        if (isOk) window.location.reload();
        messages.hideLoadingMessage();
    }

    useEffect(async () => {
        document.title = 'SIFFS - Presentaciones';
        messages.showLoadingMessage();
        const _viewmodel = await ViewModel();
        setviewModel(_viewmodel);
        const _isdarktheme = await getTheme();
        setIsDarkTheme(_isdarktheme === 'dark');
        messages.hideLoadingMessage();
    }, [])

    return (
        <Layout>
            <>
                {htmlElementMessages}
                <h4>Presentaciones</h4>
                <p className='Containerdescripcion'>Modulo para la gestion de presentaciones de sistema</p>
                <hr />
                <Row>
                    <Col lg={3}>
                        <Form.Label>ID</Form.Label>
                        <Form className='presentation-form'>
                            <Form.Control
                                type='text'
                                placeholder='ID'
                                name='prese_Id'
                                value={presentationData.prese_Id}
                                onChange={e => handleinputChange(e)}
                            />
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Nombre'
                                name='prese_Name'
                                value={presentationData.prese_Name}
                                onChange={e => handleinputChange(e)}
                            />
                            <Form.Label>Peso (Kg)</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Peso total'
                                name='prese_Weight'
                                value={presentationData.prese_Weight}
                                onChange={e => handleinputChange(e)}
                                onFocus={() => { setIsWieghtDescriptionVisible(true) }}
                                onBlur={() => { setIsWieghtDescriptionVisible(false) }}
                            />
                            {isWieghtDescriptionVisible && (<p style={{ color: isDarkTheme ? '#D7D7D7' : 'blue' }}>Peso total de la presentación. </p>)}
                            <Form.Label>Piezas</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Piezas'
                                name='prese_Pieces'
                                value={presentationData.prese_Pieces}
                                onChange={e => handleinputChange(e)}
                                onFocus={() => { setIsPieceDescriptionVisible(true) }}
                                onBlur={() => { setIsPieceDescriptionVisible(false) }}
                            />
                            {isPieceDescriptionVisible && (<p style={{ color: isDarkTheme ? '#D7D7D7' : 'blue' }}>La cantidad de piezas del producto base que contiene la presentación </p>)}
                            <br />
                            <Button variant='success' onClick={() => {
                                messages.showConfirmMessage(() => { return () => { handleonSubmit(); } });
                            }} >
                                Guardar
                            </Button>
                            &nbsp;&nbsp;
                            <Button type='button' variant='secondary' onClick={() => {
                                setPresetationData({
                                    prese_Id: 0,
                                    prese_Name: '',
                                    prese_Weight: 0,
                                    prese_MeasUnit: 0,
                                    Prese_pieces: 0,
                                })
                            }}>
                                Nuevo
                            </Button>
                        </Form>
                    </Col>
                    <Col className='presentation-list-container-col'>
                        <Form.Control type='text' placeholder='Buscar' className='SearchVegetable' />
                        <img
                            className='btn-Search'
                            src={icono_buscar}
                            width={25}
                            height={25}
                            alt='botton search'
                        />
                        <Table
                            striped
                            hover
                            className='GenericTable text-center'
                        >
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Nombre</th>
                                    <th>Peso (Kg)</th>
                                    <th>Piezas</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    viewModel && viewModel.presentations.map((presentation, index) => (
                                        <tr key={index} onClick={() => { handleEditClick(presentation); }}>
                                            <td>{presentation.id}</td>
                                            <td>{presentation.name}</td>
                                            <td>{presentation.weight}</td>
                                            <td>{presentation.unitsQuantity}</td>
                                            <td>
                                                <span>
                                                    <img
                                                        src={icono_editar}
                                                        alt='icon_edit'
                                                        onClick={() => { handleEditClick(presentation); }}
                                                    />
                                                    &nbsp;|&nbsp;
                                                    <img
                                                        src={icono_cancelar}
                                                        alt=' icon_delete'
                                                        onClick={() => {
                                                            messages.showConfirmMessage(() => { return () => { handleDeleteClick(presentation) } });
                                                        }}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default Presentation;