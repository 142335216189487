import Layout from 'components/General/layout';
import React, { useEffect, useState } from 'react';
import './warehouse.css';

//Imagenes
import { icono_editar, icono_buscar, icono_cancelar } from '../../../Assets/Images/images';

import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import ErrorMessage from 'components/Mensajes/ErrorMessage';
import Loading from 'components/Mensajes/loading';
import ConfirmMessage from 'components/Mensajes/ConfirmMessage';
import Cookies from 'js-cookie';
import { UrlApi } from 'Global/Globals';
import UseMessages from 'components/Mensajes/messages_section';
import { Delete, Save, ViewModel } from 'Data/Warehouses/warehousesData';



const Warehouse = () => {
    const messages = UseMessages();
    const htmlElementMessages = messages.messagesHTMLElement;
    const [viewModel, setViewModel] = useState(null);
    const [warehouseData, setWarehouseData] = useState({
        ware_Id: 0,
        ware_Name: '',
        ware_Region: 0
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setWarehouseData((prevdata) => ({
            ...prevdata,
            [name]: value,
        }))
    }

    const handleEditClick = (warehouse) => {
        setWarehouseData({
            ware_Id: warehouse.id,
            ware_Name: warehouse.name,
            ware_Region: warehouse.ceDi.id
        })
    }

    const handleDeleteClick = (warehouse) => {
        messages.showLoadingMessage();
        const ware_data = {
            id: warehouse.ware_Id
        }

        const ok = Delete(ware_data);
        if (ok) window.location.reload();
        messages.hideLoadingMessage();
    }

    const handleSaveClick = async () => {
        messages.showLoadingMessage();
        const ware_data = {
            id: Number(warehouseData.ware_Id ?? 0),
            name: warehouseData.ware_Name,
            cedi: { id: Number(warehouseData.ware_Region) }
        };

        const ok = Save(ware_data);
        if (ok) window.location.reload();
        messages.hideLoadingMessage();
    }

    useEffect(async () => {
        document.title = 'SIFFS - Proveedores';
        messages.showLoadingMessage();
        const _viewModel = await ViewModel();
        setViewModel(_viewModel);
        messages.hideLoadingMessage();
    }, [])

    return (
        <Layout>
            {htmlElementMessages}
            <>
                <h4>Almacenes</h4>
                <p className='Containerdescripcion'>
                    Modulo para la gestión de Almacenes disponibles en sistema
                </p>
                <hr />
                <Row>
                    <Col lg={3}>
                        <Form className='warehouse-Form'>
                            <Form.Label>ID</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='ID'
                                name='ware_Id'
                                value={warehouseData.ware_Id}
                                onChange={(e) => { handleInputChange(e); }}
                            />
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Nombre'
                                name='ware_Name'
                                value={warehouseData.ware_Name}
                                onChange={(e) => { handleInputChange(e); }}
                            />
                            <Form.Label>Centro de distribución</Form.Label>
                            <Form.Select
                                name='ware_Region'
                                value={warehouseData.ware_Region}
                                onChange={(e) => { handleInputChange(e); }}
                            >
                                <option value={0}>Seleccionar CeDi</option>
                                {
                                    viewModel?.ceDis?.map(cedi => (
                                        <option key={cedi.id} value={cedi.id}>{cedi.name}</option>
                                    ))
                                }
                            </Form.Select>
                            <br />
                            <Button
                                variant='success'
                                onClick={() => {
                                    messages.showConfirmMessage(() => { return () => { handleSaveClick(); } })
                                }}
                            >
                                Guardar
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                variant='secondary'
                                onClick={() => {
                                    setWarehouseData({
                                        ware_Id: 0,
                                        ware_Name: '',
                                        ware_Region: '',
                                        ware_Isphysical: false
                                    })
                                }}
                            >
                                Nuevo
                            </Button>
                        </Form>
                    </Col>
                    <Col className='warehouse-table-container' >
                        <Form.Control type='text' placeholder='Buscar' className='SearchVegetable' />
                        <img
                            className='btn-Search'
                            src={icono_buscar}
                            width={25}
                            height={25}
                            alt='botton search'
                        />
                        <Table
                            striped
                            hover
                            className='GenericTable'
                        >
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>Centro de distribución</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    viewModel?.warehouses?.map((warehouse, index) => (
                                        <tr key={index} onClick={() => { handleEditClick(warehouse); }}>
                                            <td className='text-center'>{warehouse.id}</td>
                                            <td className='text-center'>{warehouse.name}</td>
                                            <td className='text-center'>{warehouse.ceDi.name}</td>
                                            <td className='text-center'>
                                                <span>
                                                    <img
                                                        src={icono_editar}
                                                        alt='icon_edit'
                                                        onClick={() => { handleEditClick(warehouse); }}
                                                    />
                                                    &nbsp;|&nbsp;
                                                    <img
                                                        src={icono_cancelar}
                                                        alt=' icon_delete'
                                                        onClick={() => {
                                                            messages.showConfirmMessage(() => { return () => { handleDeleteClick(warehouse); } })
                                                        }}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default Warehouse;