import UseMessages from 'components/Mensajes/messages_section';
import Layout from 'components/General/layout';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { BranchesViewModel, ClientGet, GetBranch, SaveBranch } from 'Data/Clients/clients';

const Branches = () => {
    const messages = UseMessages();
    const messagesHTMLElement = messages.messagesHTMLElement;
    const navigate = useNavigate();
    const { clientId, branchId } = useParams();
    const [clientName, setClientName] = useState('');
    const [viewModel, setViewModel] = useState(null);
    const [branchData, setBranchData] = useState({
        id: 0,
        name: '',
        address: '',
        contactName: '',
        contactPhone: '',
        email: '',
        clientId: clientId,
        ceDiId: 0,
        orderDays: []
    });

    const handleOnChange = (e) => {
        const { value, name } = e.target;
        setBranchData(prevData => ({ ...prevData, [name]: value }))
    }

    const handleOnClickSave = () => {
        messages.showConfirmMessage(() => {
            return async () => {
                messages.showLoadingMessage();
                const isok = await SaveBranch(branchData);
                if (isok) window.location.reload();
                messages.hideLoadingMessage();
            }
        })
    }

    useEffect(async () => {
        messages.showLoadingMessage();

        if (!clientId || isNaN(clientId)) {
            messages.showErrorMessage("No se especifico cliente. Redireccionando...");
            setTimeout(() => {
                navigate('/clients');
            }, 5000);
            return;
        }

        const _client = await ClientGet({ id: clientId });
        setClientName(_client.name);

        if (branchId) {
            const _BranchInfo = await GetBranch(branchId);
            console.log(_BranchInfo);
            setBranchData(_BranchInfo);
        }

        const _viewmodel = await BranchesViewModel();
        setViewModel(_viewmodel);
        messages.hideLoadingMessage();
    }, [])

    return (
        <Layout>
            {messagesHTMLElement}
            <h4>Sucursales</h4>
            <p className='text-gray-500'>
                Modulo para crear sucursales asociadas al cliente
            </p>
            <hr />
            <Button
                onClick={() => {
                    setBranchData({
                        id: 0,
                        name: '',
                        address: '',
                        contactName: '',
                        contactPhone: '',
                        email: '',
                        clientId: clientId,
                        ceDiId: 0,
                        orderDays: []
                    })
                }}
            >
                Nueva
            </Button>
            <Button
                variant='secondary ml-2'
                onClick={() => { navigate('/clients/' + clientId); }}
            >
                Ir a cliente
            </Button>
            <Button
                variant='secondary ml-2'
                onClick={() => { navigate('/clients/branches/list/' + clientId); }}
            >
                Ir a lista
            </Button>
            <hr />
            <Form className='w-75 m-auto'>
                <h5 className='text-gray-500'>Informacion General</h5>
                <hr />
                <Row>
                    <Col md={3}>
                        <Form.Label>Cliente</Form.Label>
                        <Form.Control
                            type='text'
                            value={clientName}
                            onChange={() => { }} //Esto es para evitar los warnings
                            disabled
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Nombre'
                            value={branchData.name}
                            name='name'
                            onChange={handleOnChange}
                        />
                    </Col>
                </Row>
                <Row>

                    <Col md={4} xl={9}>
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Dirección'
                            value={branchData.address}
                            name='address'
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col md={4} xl={3}>
                        <Form.Label className='nowrap'>CeDi</Form.Label>
                        <Form.Select
                            value={branchData.ceDiId}
                            name='ceDiId'
                            onChange={handleOnChange}
                        >
                            <option>Seleccione región...</option>
                            {
                                viewModel?.ceDis?.map(cedi => (
                                    <option key={cedi.id} value={cedi.id}>{cedi.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col md={3}>
                        <Form.Label>Nombre Encargado</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Contacto'
                            value={branchData.contactName}
                            name='contactName'
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Numero telefonico</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Telefono'
                            value={branchData.contactPhone}
                            name='contactPhone'
                            onChange={handleOnChange}
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Label>Correo electronico</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Email'
                            value={branchData.email}
                            name='email'
                            onChange={handleOnChange}
                        />
                    </Col>
                </Row>
                <br />
                <h5 className='text-gray-500'>Información de Pedido</h5>
                <hr />
                <Row>
                    <Col md={3} className='w-100 d-flex flex-col items-center'>
                        <Form.Label>Dias de pedido</Form.Label>
                        <Table className='GenericTable w-25 text-center'>
                            <thead>
                                <tr>
                                    <th>Dia</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><Form.Check id='lunes-chbx' label='Lunes' /></td>
                                </tr>
                                <tr>
                                    <td><Form.Check id='martes-chbx' label='Martes' /></td>
                                </tr>
                                <tr>
                                    <td><Form.Check id='miercoles-chbx' label='Miercoles' /></td>
                                </tr>
                                <tr>
                                    <td><Form.Check id='jueves-chbx' label='Jueves' /></td>
                                </tr>
                                <tr>
                                    <td><Form.Check id='viernes-chbx' label='Viernes' /></td>
                                </tr>
                                <tr>
                                    <td><Form.Check id='sabado-chbx' label='Sabado' /></td>
                                </tr>
                                <tr>
                                    <td><Form.Check id='domingo-chbx' label='domingo' /></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Button
                    variant='success mt-3'
                    onClick={handleOnClickSave}
                >
                    Guardar
                </Button>
            </Form>
        </Layout>
    );
}

export default Branches;