import { freshco_logo_azul } from 'Assets/Images/images';
import React, { useEffect, useState } from 'react';
import './complaints.css';
import { Form, Col, Row, Button } from 'react-bootstrap';

const Complaints = ({ token }) => {
    const [TokenExists, setIsTokenExists] = useState(false);
    useEffect(() => {
        setIsTokenExists((token !== undefined && token !== null))
    }, [])

    return (
        // Main container
        <div className='fixed w-screen h-screen bg-white'>
            {/* Header */}
            < div className='w-full bg-slate-200 p-4 border-b-2 border-gray-400' >
                <img src={freshco_logo_azul} className='w-32' />
            </ div>
            {/* content */}
            < div className='p-3' >
                <h4>Formulario</h4>
                <p className='Containerdescripcion'>
                    Haganos saber como podemos mejorar nuestro servicio.
                </p>
                <hr />
                <Row className='mb-4'>
                    <Col md={3}>
                        <Form.Control
                            className='ml-0 ml-md-3 mt-2 mt-md-0'
                            type='text'
                            placeholder='Cliente'
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            className='ml-0 ml-md-3 mt-2 mt-md-0'
                            type='text'
                            placeholder='Sucursal'
                        />
                    </Col>
                </Row>
                <Row className='mb-4'>
                    <Col md={3}>
                        <Form.Control
                            className='ml-0 ml-md-3 mt-2 mt-md-0'
                            type='text'
                            placeholder='Nombre de quien reportar'
                        />
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            className='ml-0 ml-md-3 mt-2 mt-md-0'
                            type='text'
                            placeholder='Telefono'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Control
                            className='complaints-description ml-3 w-75 max-h-36'
                            as='textarea'
                            rows={3}
                            placeholder='Descripción'
                        />
                    </Col>
                </Row>
                <Button variant='success w-28 mt-5 mx-3'>
                    Enviar
                </Button>
            </ div>
        </div>
    )
}

export default Complaints;