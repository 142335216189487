import Cookies from "js-cookie";
import axios from "axios";
import { api_endpoints } from "Global/Globals";
const token = Cookies.get('token');


export async function ClientsViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_clients_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        if (error.message) {
            console.error(error.message);
        }
        else {
            console.error(error);
        }
        return null;
    }
}

export async function OrdersViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_clientsOrders_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        if (error.message) {
            console.error(error.message);
        }
        else {
            console.error(error);
        }
        return null;
    }
}

export async function SaveClient(client) {
    try {
        await axios.post(api_endpoints.save_client, client, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export async function ClientlistViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_clientList_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        console.error(error.message);
        return [];
    }
}

export async function ClientBlock(client) {
    try {
        await axios.post(api_endpoints.Lock_client, client, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.error(error.message);
        return false;
    }
}

export async function ClientGet(client) {
    try {
        const { id } = client;
        const { data } = await axios.get(`${api_endpoints.get_client}/${id}`, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        console.error(error.message);
        return null;
    }
}

export async function SaveCorporative(Corporative) {
    try {
        await axios.post(api_endpoints.save_corporative, Corporative, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.error(error.message);
        return false;
    }
}

export async function BranchesViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_branches_viewmodel, { headers: { 'Authorization': token } })
        return data;
    } catch (error) {
        console.error(error.message);
        return [];
    }
}

export async function SaveBranch(branch) {
    try {
        await axios.post(api_endpoints.save_branch, branch, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.error(error.message);
        return false;
    }
}

export async function DeleteBranch(BranchId) {
    try {
        await axios.get(api_endpoints.delete_branch + `/${BranchId}`, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.error(error.message);
        return false;
    }
}

export async function BranchesListViewModel(clientId) {
    try {
        const { data } = await axios.get(api_endpoints.get_branchesList_viewmodel + '/' + clientId, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        console.error(error.message);
        return [];
    }
}

export async function GetBranch(BranchId) {
    try {
        const { data } = await axios.get(`${api_endpoints.get_branch}/${BranchId}`, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        console.error(error.message);
        return [];
    }
}

export async function AssociateItemsViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_associateitem_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        console.error(error.message);
        return [];
    }
}

export async function AddItemToClient(item) {
    try {
        await axios.post(api_endpoints.add_associateitem_to_client, item, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.error(error.message);
        return false;
    }
}

export async function RemoveItemToClient(itemId) {
    try {
        await axios.get(api_endpoints.remove_associateitem_to_client + `/${itemId}`, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.error(error.message);
        return false;
    }
}

export async function AssociateItemBranchViewModel(clientId) {
    try {
        const { data } = await axios.get(api_endpoints.get_associateitem_branch_viewmodel + `/${clientId}`, { headers: { 'Authorization': token } })
        return data;
    } catch (error) {
        console.error(error.message);
        return [];
    }
}

export async function AddItemToBranch(item) {
    try {
        await axios.post(api_endpoints.add_associateitem_to_branch, item, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.error(error.message);
        return false;
    }
}

export async function RemoveItemToBranch(itemId) {
    try {
        await axios.get(api_endpoints.remove_associateitem_to_branch + `/${itemId}`, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.error(error.message);
        return false;
    }
}