import React, { useEffect, useState } from 'react';
import './items.css';
import Layout from '../../../components/General/layout';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { icono_cancelar, icono_editar } from '../../../Assets/Images/images';
import UseMessages from 'components/Mensajes/messages_section';
import { Delete, Save, ViewModel } from 'Data/items/itemsData';


const Items = () => {
    const [isFormVisible, setIsFormVisible] = useState(true);
    const [ItemData, setItemData] = useState({
        Id: 0,
        Name: '',
        ShortName: '',
        Vegetable: 0,
        Presentation: 0,
        Cut: 0,
        codeSAT: 0,
    });

    const handleEditItem = (itemToEdit) => {
        console.log(itemToEdit);
        setItemData({
            Id: itemToEdit.id,
            Name: itemToEdit.name,
            Vegetable: itemToEdit.itembase.id,
            Presentation: itemToEdit.presentation.id,
            codeSAT: itemToEdit.codeSAT
        })

        setIsFormVisible(true);
    }

    useEffect(async () => {
        document.title = 'SIFFS - Productos';
    }, [])

    return (
        <Layout>
            <>
                <h4>Productos</h4>
                <p className='Containerdescripcion'>
                    Modulo para realizar la gestión general del catalogo de productos
                </p>
                <hr />
                <Button
                    variant={isFormVisible ? 'secondary' : 'primary'}
                    onClick={() => { setIsFormVisible(!isFormVisible) }}
                >
                    {isFormVisible ? 'Productos' : 'Nuevo'}
                </Button>
                <hr />
                {
                    isFormVisible && (<FormItems exItemData={ItemData} />)
                }
                {
                    !isFormVisible && (<DatagridItems setItemEdit={handleEditItem} />)
                }
            </>
        </Layout>
    );
}

const FormItems = ({ exItemData }) => {
    const messages = UseMessages();
    const htmlElementMessages = messages.messagesHTMLElement;
    const [viewModel, setViewModel] = useState(null);
    const [ItemData, setItemData] = useState(exItemData);


    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setItemData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const concatNameLong = (item) => {
        if (viewModel === null || !viewModel) return "";
        const vegetableSelected = viewModel.itembases.find(obj => obj.id === Number(item.Vegetable));
        const presentationSelected = viewModel.presentations.find(obj => obj.id === Number(item.Presentation));

        let vegetableName = '';
        let presentationName = '';
        if (vegetableSelected) { vegetableName = vegetableSelected.name }
        if (presentationSelected) { presentationName = presentationSelected.name }

        const itemName = presentationName + ' de ' + vegetableName;
        return itemName === ' de  ' ? '' : itemName;
    }

    const handleSaveClick = async () => {
        messages.showLoadingMessage();
        const _item = {
            id: Number(ItemData.Id) ?? 0,
            name: '',
            itembase: { id: Number(ItemData.Vegetable) },
            presentation: { id: Number(ItemData.Presentation) },
            codeSAT: ItemData.codeSAT
        };

        const isOk = await Save(_item);
        if (isOk) window.location.reload();
        messages.hideLoadingMessage();
    }

    useEffect(async () => {
        messages.showLoadingMessage();
        const _viewmodel = await ViewModel();
        setViewModel(_viewmodel);
        console.log(_viewmodel);
        messages.hideLoadingMessage();
    }, []);

    return (
        <>
            {htmlElementMessages}
            <Form className='FormItems'>
                <Row>
                    <Col lg={3}>
                        <Form.Label>ID</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Id'
                            name='Id'
                            value={ItemData.Id}
                            onChange={handleInputChange}
                        />
                    </Col>
                    <Col>
                        <Form.Label>Nombre Comercial</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Nombre'
                            name='Name'
                            value={concatNameLong(ItemData)}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <h5 className='Containerdescripcion'>General</h5>
                <hr />
                <Row>
                    <Col lg={6}>
                        <Form.Label>Producto Base</Form.Label>
                        <Form.Select
                            name='Vegetable'
                            value={ItemData.Vegetable}
                            onChange={(e) => { handleInputChange(e); }}
                        >
                            <option value={0}>Selecciona un producto base...</option>
                            {
                                viewModel?.itembases?.map(itembase => (
                                    <option key={itembase.id} value={itembase.id} >{itembase.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col lg={6}>
                        <Form.Label>Presentación</Form.Label>
                        <Form.Select
                            name='Presentation'
                            value={ItemData.Presentation}
                            onChange={(e) => { handleInputChange(e); }}
                        >
                            <option value={''}>Seleccione Presentación...</option>
                            {
                                viewModel && viewModel.presentations.map(presentation => (
                                    <option
                                        key={presentation.id}
                                        value={presentation.id}
                                    >
                                        {presentation.name}
                                    </option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col lg={6}>
                        <Form.Label>Codigo de producto (SAT)</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Codigo producto o servicio'
                            onChange={handleInputChange}
                            name='codeSAT'
                            value={ItemData.codeSAT}
                        />
                        <a href='http://pys.sat.gob.mx/PyS/catPyS.aspx' target='_blank' className='text-white'>Ir a busqueda SAT</a>
                    </Col>
                    <Col lg={6}>
                        <Form.Label>Unidad de medida (SAT)</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Unidad de medida sat'
                        />
                        <a href='http://pys.sat.gob.mx/PyS/catUnidades.aspx' target='_blank' className='text-white'>Ir a catalogo SAT</a>
                    </Col>
                </Row>
                <br />
                <Button
                    variant='success'
                    onClick={() => {
                        messages.showConfirmMessage(() => { return () => { handleSaveClick(); } })
                    }}
                >
                    Guardar
                </Button>
                &nbsp;&nbsp;
                <Button
                    variant='secondary'
                    onClick={() => {
                        setItemData({
                            Id: 0,
                            Name: '',
                            ShortName: '',
                            Vegetable: 0,
                            Presentation: 0,
                            Cut: 0,
                        })
                    }}
                >
                    Nuevo
                </Button>
            </Form>
        </>
    )
}

const DatagridItems = ({ setItemEdit }) => {

    const [viewModel, setViewModel] = useState([]);
    const messages = UseMessages();
    const htmlElementMessages = messages.messagesHTMLElement;

    const handleDeleteClick = async (item) => {
        messages.showLoadingMessage();
        const isOk = await Delete(item);
        if (isOk) window.location.reload();
        messages.hideLoadingMessage();
    }

    useEffect(async () => {
        messages.showLoadingMessage();
        const _viewmodel = await ViewModel();
        setViewModel(_viewmodel);
        messages.hideLoadingMessage();
    }, [])

    return (
        <>
            {htmlElementMessages}
            <Table
                className='GenericTable text-center'
                striped
                hover
            >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        viewModel?.items?.map((item, index) => (
                            <tr key={item.id} >
                                <td>{item.id}</td>
                                <td>{item.name}</td>
                                <td>
                                    <span>
                                        <img
                                            src={icono_editar}
                                            alt='icon_edit'
                                            onClick={() => { setItemEdit(item); }}
                                        />
                                        &nbsp;|&nbsp;
                                        <img
                                            src={icono_cancelar}
                                            alt=' icon_delete'
                                            onClick={() => {
                                                messages.showConfirmMessage(() => { return () => { handleDeleteClick(item); } })
                                            }}
                                        />
                                    </span>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </>
    )
}

export default Items;
