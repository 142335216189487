import { api_endpoints } from "Global/Globals";
import axios from "axios";
import Cookies from "js-cookie";


const token = Cookies.get('token');

export async function ViewModel() {
    try {

        const { data } = await axios.get(api_endpoints.get_items_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        console.log(error.message);
        return null;
    }
}

export async function Save(item) {
    try {
        console.log(item);
        await axios.post(api_endpoints.save_items, item, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function Delete(item) {
    try {
        console.log(item);
        await axios.post(api_endpoints.delete_items, item, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.log(error);
        return null;
    }
}