import React, { useEffect, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import Cookies from "js-cookie";
import { useNavigate } from 'react-router-dom';
import { UrlApi, api_endpoints } from '../../Global/Globals';

import freshcologo from "../../Assets/Images/freshco-blanco.png";
import wallaper from "../../Assets/Images/Fondo_Login.jpg";
import './login.css';
import UseMessages from 'components/Mensajes/messages_section';
import axios from 'axios';
import { login, validateToken } from 'Data/Employees/employeesData';


function Login() {
    const [originalBackground, setOriginalBackground] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const message = UseMessages();
    const messagesHTMLElement = message.messagesHTMLElement;
    const navigate = useNavigate();

    const setBackGroundImage = () => {
        document.body.style.backgroundColor = '#0268AA';
        document.body.style.background = `url(${wallaper}) no-repeat`;
        document.body.style.backgroundSize = `cover`;
    }

    useEffect(async () => {
        document.title = 'SIFFS - Login';
        message.showLoadingMessage();
        setBackGroundImage();

        if (originalBackground === null) {
            setOriginalBackground(document.body.style.backgroundColor);
        }

        const isValidToken = await validateToken();
        if (isValidToken) { navigate('/home', { replace: true }); }
        message.hideLoadingMessage();
        return () => {
            document.body.style.backgroundColor = originalBackground;
            document.body.style.backgroundImage = '';
        };
    }, [])

    const HandleusernameChange = async (event) => {
        setUsername(event.target.value);
    }

    const HandlePasswordChange = async (event) => {
        setPassword(event.target.value);
    }

    const CheckUserLogin = async () => {
        message.showLoadingMessage();
        const isLoginCorrect = await login(username, password);
        if (isLoginCorrect === 'locked') {
            message.hideLoadingMessage();
            message.showErrorMessage("Usuario bloqueado. Contacte a soporte tecnico.");
        }
        if (isLoginCorrect === 'error') {
            message.hideLoadingMessage();
            message.showErrorMessage("Credenciales de acceso no correctas.");
        }
    }

    const HandlePasswordReset = () => {
        message.showErrorMessage("¡Vaya! aun no tenemos todo listo para esta situación.");
    }

    const handlePressKey = (e) => {
        if (e.key === 'Enter') {
            CheckUserLogin();
        }
    }

    return (
        <>
            {messagesHTMLElement}
            <img src={freshcologo} alt='Freshco Logo' className='logoLogin' />
            <Row className='login-container'>
                <div className='login'>
                    <Form className='formLogin' >
                        <center><h4>Bienvenido</h4></center>
                        <br />
                        <Form.Group>
                            <Form.Control
                                className='text-center'
                                type='text'
                                name='Empl_UserName'
                                placeholder='Usuario'
                                value={username}
                                onChange={HandleusernameChange}
                                onKeyDown={(e) => { handlePressKey(e); }}
                            />
                            <Form.Control
                                className='mt-1 text-center'
                                type='password'
                                name='Empl_Password'
                                placeholder='Contraseña'
                                value={password}
                                onChange={HandlePasswordChange}
                                onKeyDown={(e) => { handlePressKey(e); }}
                            />
                            <br />
                            <Button variant='primary' onClick={CheckUserLogin}>
                                Entrar
                            </Button>
                        </Form.Group>
                        <br />
                        <center><Button onClick={HandlePasswordReset} variant='none' className='BtnPswRst'>Olvide mi contraseña</Button></center>
                    </Form>
                </div>
            </Row>
        </>
    );
}

export default Login;