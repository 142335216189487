import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import './clients.css';

import Layout from 'components/General/layout';
import UseMessages from 'components/Mensajes/messages_section';
import { ClientGet, ClientsViewModel, SaveClient, SaveCorporative } from 'Data/Clients/clients';
import { useNavigate, useParams } from 'react-router-dom';
import { icono_editar_color } from 'Assets/Images/images';

const Clients = () => {
    const messages = UseMessages();
    const messagesHTMLElement = messages.messagesHTMLElement;
    const [clientData, setClientData] = useState({
        id: 0,
        name: '',
        legalName: '',
        corporativeId: 0,
        rFC: '',
        street: '',
        numberInt: '',
        numberExt: '',
        CP: '',
        municipality: '',
        state: '',
        country: '',
        creditDays: 0,
        cfdiUseId: 0,
        taxRegimeId: 0,
        paymentFormId: 0,
        paymentMethodId: 0,
    });
    const [viewModel, setViewModel] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [isCorporativeFormVisible, showCorporativeForm] = useState(false);
    const [corporativeName, setCorporativeName] = useState('');

    const handleOnChange = (e) => {
        const { value, name } = e.target;

        setClientData(prevData => ({ ...prevData, [name]: value }));
    }

    const handleClickCorporativeForm = () => {
        setCorporativeName(viewModel?.corporatives?.find(c => c.id === Number(clientData.corporativeId))?.name);
        showCorporativeForm(true);
    }

    const handleOnClickNew = () => {
        setClientData({
            id: 0,
            name: '',
            legalName: '',
            corporativeId: 0,
            rFC: '',
            street: '',
            numberInt: '',
            numberExt: '',
            CP: '',
            municipality: '',
            state: '',
            country: '',
            creditDays: 0,
        });
    }

    const handleOnClickBranches = () => {
        if (clientData.id === 0) {
            messages.showErrorMessage("Seleccione un cliente primero.");
            return;
        }

        navigate('/clients/branches/list/' + clientData.id);
    }

    const save = async () => {
        messages.showLoadingMessage();
        const client = {
            id: clientData.id,
            name: clientData.name,
            legalName: clientData.legalName,
            rFC: clientData.rFC,
            street: clientData.street,
            apartamentNumber: Number(clientData.numberInt),
            streetNumber: Number(clientData.numberExt),
            postalCode: Number(clientData.CP),
            city: clientData.municipality,
            state: clientData.state,
            country: clientData.country,
            hasCredit: clientData.creditDays > 0,
            creditDays: Number(clientData.creditDays),
            corporativeId: Number(clientData.corporativeId),
            cfdiUseId: Number(clientData.cfdiUseId),
            paymentFormId: Number(clientData.paymentFormId),
            paymentMethodId: Number(clientData.paymentMethodId),
            taxRegimeId: Number(clientData.taxRegimeId),
        }
        console.log(client);
        const isOk = await SaveClient(client);
        if (isOk) window.location.reload();
        messages.hideLoadingMessage();
    }

    useEffect(async () => {
        messages.showLoadingMessage();
        document.title = 'SIFFS - Clientes';
        const _viewModel = await ClientsViewModel();
        setViewModel(_viewModel);
        if (id) {
            const clientToSearch = { id: id }
            const _dataclient = await ClientGet(clientToSearch);
            if (!_dataclient) {
                messages.showErrorMessage("No existe el cliente especificado. Contactar a soporte");
                messages.hideLoadingMessage();
                return;
            }
            setClientData({
                id: _dataclient.id,
                name: _dataclient.name,
                legalName: _dataclient.legalName,
                corporativeId: _dataclient.corporativeId ?? 0,
                rFC: _dataclient.rfc,
                creditDays: _dataclient.creditDays,
                street: _dataclient.street,
                numberInt: _dataclient.apartamentNumber,
                numberExt: _dataclient.streetNumber,
                CP: _dataclient.postalCode,
                municipality: _dataclient.city,
                state: _dataclient.state,
                country: _dataclient.country,
                cfdiUseId: _dataclient.cfdiUseId,
                paymentFormId: _dataclient.paymentFormId,
                paymentMethodId: _dataclient.paymentMethodId,
                taxRegimeId: _dataclient.taxRegimeId,
            });
        }
        messages.hideLoadingMessage();
    }, [])

    return (
        <>
            {isCorporativeFormVisible && <CorporativeForm corporativeId={clientData.corporativeId} corporativeName={corporativeName} onClose={() => { showCorporativeForm(false); }} />}
            <Layout>
                {messagesHTMLElement}
                <>
                    <h4>Cliente</h4>
                    <p className='Containerdescripcion'>
                        Modulo para gestionar los clientes en sistema
                    </p>
                    <hr />
                    <Button
                        className=''
                        variant='primary'
                        onClick={() => { handleOnClickNew(); }}
                    >
                        Nuevo
                    </Button>
                    <Button
                        className=''
                        variant='secondary ml-2'
                        onClick={() => { navigate('/clients/list'); }}
                    >
                        Lista de clientes
                    </Button>
                    <Button
                        className=''
                        variant='secondary ml-2'
                        onClick={() => { }}
                    >
                        Cuentas contables
                    </Button>
                    <Button
                        className=''
                        variant='secondary ml-2'
                        onClick={handleOnClickBranches}
                    >
                        Sucursales
                    </Button>
                    <hr />
                    <br />
                    <Form className='client-formclient'>
                        <h6 className='Containerdescripcion'>Información General</h6>
                        <hr />
                        <Row>
                            <Col lg={3}>
                                <Form.Label>ID</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='ID'
                                    name='id'
                                    value={clientData.id}
                                    onChange={handleOnChange}
                                />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>Cliente</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Cliente'
                                    name='name'
                                    value={clientData.name}
                                    onChange={handleOnChange}
                                />
                            </Col>
                            <Col lg={5}>
                                <Form.Label className='d-block mt-2 mt-lg-0'>Corporativo / Grupo</Form.Label>
                                <Form.Select
                                    name='corporativeId'
                                    value={clientData.corporativeId}
                                    onChange={handleOnChange}
                                    className='d-inline w-75'
                                >
                                    <option value={0}>Selecciona corporativo o grupo...</option>
                                    {
                                        viewModel?.corporatives?.map(corporative => (
                                            <option key={corporative.id} value={corporative.id}>{corporative.name}</option>
                                        ))
                                    }
                                </Form.Select>
                                <img
                                    src={icono_editar_color}
                                    className='d-inline ml-2'
                                    width={30}
                                    height={30}
                                    onClick={handleClickCorporativeForm}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}>
                                <Form.Label>Razón Social</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Razón social'
                                    name='legalName'
                                    value={clientData.legalName}
                                    onChange={handleOnChange}
                                />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>RFC</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='RFC'
                                    name='rFC'
                                    value={clientData.rFC}
                                    onChange={handleOnChange}
                                />
                            </Col>
                        </Row>
                        <br />
                        <h6 className='Containerdescripcion'>Información de Facturación</h6>
                        <hr />
                        <Row>
                            <Col lg={6}>
                                <Form.Label>Metodo de pago</Form.Label>
                                <Form.Select
                                    name='paymentMethodId'
                                    value={clientData.paymentMethodId}
                                    onChange={handleOnChange}
                                >
                                    <option>Seleccionar...</option>
                                    {
                                        viewModel?.paymentMethods?.map(paymentMethod => (
                                            <option key={paymentMethod.id} value={paymentMethod.id}>{paymentMethod.code} - {paymentMethod.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                            <Col lg={6}>
                                <Form.Label>Forma de pago</Form.Label>
                                <Form.Select
                                    name='paymentFormId'
                                    value={clientData.paymentFormId}
                                    onChange={handleOnChange}
                                >
                                    <option>Seleccionar...</option>
                                    {
                                        viewModel?.paymentForms?.map(paymentForm => (
                                            <option key={paymentForm.id} value={paymentForm.id}>{paymentForm.code} - {paymentForm.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6}>
                                <Form.Label>Uso de CFDI</Form.Label>
                                <Form.Select
                                    name='cfdiUseId'
                                    value={clientData.cfdiUseId}
                                    onChange={handleOnChange}
                                >
                                    <option>Seleccionar...</option>
                                    {
                                        viewModel?.cfdiUses?.map(cfdiUse => (
                                            <option key={cfdiUse.id} value={cfdiUse.id}>{cfdiUse.code} - {cfdiUse.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                            <Col lg={6}>
                                <Form.Label>Regimen Fiscal</Form.Label>
                                <Form.Select
                                    value={clientData.taxRegimeId}
                                    name='taxRegimeId'
                                    onChange={handleOnChange}
                                >
                                    <option>Seleccionar...</option>
                                    {
                                        viewModel?.taxRegimes?.map(taxRegime => (
                                            <option key={taxRegime.id} value={taxRegime.id}>{taxRegime.code} - {taxRegime.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={2} className='text-center'>
                                <Form.Label>Dias de credito</Form.Label>
                                <Form.Control
                                    className='text-center'
                                    type='number'
                                    placeholder='Credito'
                                    name='creditDays'
                                    value={clientData.creditDays}
                                    onChange={handleOnChange}
                                />
                            </Col>

                        </Row>
                        <br />
                        <h6 className='Containerdescripcion'>Dirección</h6>
                        <hr />
                        <Row>
                            <Col lg={5}>
                                <Form.Label>Calle</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Calle'
                                    name='street'
                                    value={clientData.street}
                                    onChange={handleOnChange}
                                />
                            </Col>
                            <Col lg={2}>
                                <Form.Label className='text-nowrap'>Numero Interior</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Numero interior'
                                    name='numberInt'
                                    value={clientData.numberInt}
                                    onChange={handleOnChange}
                                />
                            </Col>
                            <Col lg={2}>
                                <Form.Label className='text-nowrap'>Numero Exterior</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Numero exterior'
                                    name='numberExt'
                                    value={clientData.numberExt}
                                    onChange={handleOnChange}
                                />
                            </Col>
                            <Col lg={2}>
                                <Form.Label> CP</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Codigo postal'
                                    name='CP'
                                    value={clientData.CP}
                                    onChange={handleOnChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <Form.Label>Municipio / localidad</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='localidad'
                                    name='municipality'
                                    value={clientData.municipality}
                                    onChange={handleOnChange}
                                />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>Estado</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='Estado'
                                    name='state'
                                    value={clientData.state}
                                    onChange={handleOnChange}
                                />
                            </Col>
                            <Col lg={3}>
                                <Form.Label>País</Form.Label>
                                <Form.Control
                                    type='text'
                                    placeholder='País'
                                    name='country'
                                    value={clientData.country}
                                    onChange={handleOnChange}
                                />
                            </Col>
                        </Row>
                        <Button
                            variant='success'
                            onClick={() => { messages.showConfirmMessage(() => { return async () => { await save(); } }) }}
                        >
                            Guardar
                        </Button>
                    </Form>
                </>
            </Layout>
        </>
    );
}

const CorporativeForm = ({ corporativeId, corporativeName, onClose }) => {
    const [name, setName] = useState(corporativeName ?? '');

    const save = async () => {
        const corporative = {
            id: corporativeId,
            name: name
        }

        const isok = await SaveCorporative(corporative);
        if (isok) window.location.reload();
        onClose();
    }

    return (
        <div className='flex flex-col items-center justify-center fixed w-screen h-screen m-0 bg-zinc-900/[0.7]' style={{ zIndex: 2 }}>
            <div className='w-1/4 h-2/4 bg-slate-700 shadow-md border-gray-400 border-2 text-center flex flex-col items-center justify-center rounded text-white'>
                <h4>Corporativo o grupo</h4>
                <Form className='mt-4'>
                    <Form.Label>Nuevo {corporativeId !== 0 ? 'Nombre' : 'Corporativo o grupo'}</Form.Label>
                    <Form.Control
                        type='text'
                        placeholder='nombre'
                        className='mt-2'
                        name='name'
                        value={name}
                        onChange={(e) => { setName(e.target.value); }}
                    />
                    <Button
                        variant='success mt-4'
                        onClick={save}
                    >
                        Guardar
                    </Button>
                    <Button
                        variant='danger mt-4 ml-2'
                        onClick={() => { onClose(); }}
                    >
                        Cancelar
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default Clients;