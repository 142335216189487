import React from 'react';
import {  NavItem } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './styles/error.css';

// Imagenes
import erroricon from '../../Assets/Images//errorIcon.png';

function ErrorPage() {
    const navigate = useNavigate();
    const gotoHome = ()=>{
        navigate('/Login',{replace:true});
    }

    return (
        <div className='ErrorContainer'>
           <div>   
                <center><img src={erroricon} alt='imagen error'/></center>
                <center><h2>ERROR 404</h2></center>
                <center><p>page not found</p></center>
                <center><NavItem className='goHomeFromErrorLink' onClick={gotoHome}>Go Home</NavItem></center>
           </div>
           <div class="clouds">
            <div className='cloud'></div>
           </div>
        </div>
    );
}

export default ErrorPage;