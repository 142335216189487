import React, { useEffect, useState } from 'react';
import './ItemsPerVendor.css';
import Layout from 'components/General/layout';
import icono_editar from "../../../Assets/Images/icono_Editar.png";
import icono_borrar from "../../../Assets/Images/icono_Eliminar.png";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import UseMessages from 'components/Mensajes/messages_section';
import { IViewModel, VendorItemAdd, VendorItemRemove } from 'Data/Vendors/vendorData';

const Itemspervendor = () => {
    const messages = UseMessages();
    const htmlElementMessages = messages.messagesHTMLElement;
    const { vendorId } = useParams();
    const [viewModel, setViewModel] = useState(null);
    const [dataVendorItem, setDataVendorItem] = useState({
        item_Id: 0,
        itemsale_id: 0,
        item_Cost: 0,
        item_Name: '',
        item_notsameitemvendorclient: false,
        item_currency_id: 1
    });
    const navigate = useNavigate();

    const handleOnChange = (e) => {
        const { name, value, checked } = e.target;

        if (name === 'item_Cost') {
            const isValidInput = /^(\d*\.?\d*)$/.test(value.substring(1));
            if (!isValidInput) return;
        }

        setDataVendorItem((lastData) => ({
            ...lastData,
            [name]: name === 'item_notsameitemvendorclient' ? checked : value
        }))
    }

    const handlenewClick = () => {
        setDataVendorItem({
            item_Id: 0,
            itemsale_id: 0,
            item_Cost: 0,
            item_Name: '',
            item_notsameitemvendorclient: false,
            item_currency_id: 1
        });
    }

    const handleEditClick = (vendorItem) => {
        const { item, itemSale, cost, isSameItemVendorClient, currency } = vendorItem;
        console.log(item, itemSale, cost, isSameItemVendorClient, currency);
        setDataVendorItem({
            item_Id: item?.id ?? 0,
            itemsale_id: itemSale?.id ?? 0,
            item_Name: item.name,
            item_Cost: cost,
            item_notsameitemvendorclient: !isSameItemVendorClient,
            item_currency_id: currency?.id
        })
    }

    const handleAddClick = async () => {
        messages.showLoadingMessage();
        const vendorItem = {
            id: 0,
            vendor: { id: Number(viewModel.vendor.id ?? 0) },
            isSameItemVendorClient: !dataVendorItem.item_notsameitemvendorclient,
            item: { id: Number(dataVendorItem.item_Id ?? 0) },
            itemPurchase: { id: Number(dataVendorItem.item_Id ?? 0) },
            itemSale: { id: Number(dataVendorItem.itemsale_id ?? 0) },
            cost: Number(dataVendorItem.item_Cost ?? 0),
            currency: { id: Number(dataVendorItem.item_currency_id ?? 0) }
        };

        const isOk = await VendorItemAdd(JSON.stringify(vendorItem));
        if (isOk) window.location.reload();
        messages.hideLoadingMessage();
    }

    const handleDeleteClick = async (vendorItem) => {
        messages.showLoadingMessage();
        const isOk = await VendorItemRemove({ id: vendorItem.id });
        if (isOk) window.location.reload();
        messages.hideLoadingMessage();
    }

    useEffect(async () => {
        document.title = 'SIFFS - Producto x proveedor';
        messages.showLoadingMessage();
        const _viewModel = await IViewModel(vendorId);
        setViewModel(_viewModel);
        console.log(_viewModel);
        messages.hideLoadingMessage();
    }, [])

    return (
        <Layout>
            {htmlElementMessages}
            <>
                <h4 className='mt-2'>Relacionar productos a proveedores</h4>
                <p className='Containerdescripcion'>
                    Modulo para relacionar productos disponibles a proveedores
                </p>
                <hr />
                <Button
                    variant='secondary'
                    onClick={() => { navigate('/vendors', { replace: true }) }}
                >
                    Regresar a proveedor
                </Button>
                <hr />
                <Row>
                    <Col md={3}>
                        <Form className='p-md-2'>
                            <Form.Label>Proveedor</Form.Label>
                            <Form.Control
                                defaultValue={viewModel?.vendor?.name}
                                disabled
                            />
                            <Form.Label className='mt-3'> {dataVendorItem.item_notsameitemvendorclient ? 'Producto Compra' : 'Producto'} </Form.Label>
                            <Form.Select
                                value={dataVendorItem.item_Id}
                                name='item_Id'
                                onChange={(e) => { handleOnChange(e); }}
                            >
                                <option value={0}>Selecciona producto ...</option>
                                {
                                    viewModel?.items?.map(item => (
                                        <option
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </option>
                                    ))
                                }
                            </Form.Select>
                            <Form.Check
                                className='mt-2'
                                label='Homologar'
                                id='homologar_check'
                                name='item_notsameitemvendorclient'
                                checked={dataVendorItem.item_notsameitemvendorclient}
                                onChange={handleOnChange}
                            />
                            {
                                dataVendorItem.item_notsameitemvendorclient && (
                                    <>
                                        <Form.Label className='mt-3'> Producto venta</Form.Label>
                                        <Form.Select
                                            value={dataVendorItem.itemsale_id}
                                            name='itemsale_id'
                                            onChange={(e) => { handleOnChange(e); }}
                                        >
                                            <option value={0}>Selecciona producto ...</option>
                                            {
                                                viewModel?.items?.map(item => (
                                                    <option
                                                        key={item.id}
                                                        value={item.id}
                                                    >
                                                        {item.name}
                                                    </option>
                                                ))
                                            }
                                        </Form.Select>
                                    </>
                                )
                            }
                            <Form.Label className='mt-3'>Costo</Form.Label>
                            <div className='flex flex-row'>
                                <Form.Control
                                    className='text-center mr-1'
                                    type='text'
                                    placeholder='Costo'
                                    name='item_Cost'
                                    value={dataVendorItem.item_Cost}
                                    onChange={(e) => { handleOnChange(e) }}
                                />

                                <Form.Select
                                    name='item_currency_id'
                                    value={dataVendorItem.item_currency_id}
                                    onChange={(e) => { handleOnChange(e) }}
                                >
                                    {
                                        viewModel?.currencies?.map((currency, index) => (
                                            <option
                                                key={index}
                                                value={currency.id}
                                            >
                                                {currency.name}
                                            </option>
                                        ))
                                    }
                                </Form.Select>
                            </div>
                            <Button
                                variant='primary mt-4'
                                onClick={() => {
                                    messages.showConfirmMessage(() => { return () => { handleAddClick(); } });
                                }}
                            >
                                Agregar
                            </Button>
                            <Button
                                variant='secondary ms-2 mt-4'
                                onClick={handlenewClick}
                            >
                                Nuevo
                            </Button>
                        </Form>
                    </Col>
                    <Col className='tables-container mt-5 mt-md-1'>
                        <Table
                            hover
                            striped
                            className='GenericTable'
                        >
                            <thead>
                                <tr>
                                    <th>Clave Producto</th>
                                    <th>Producto</th>
                                    <th>Producto homologado</th>
                                    <th>Costo</th>
                                    <th>Moneda</th>
                                    <th>Homologado</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {
                                    viewModel?.vendor?.items?.map((vendorItem, index) => (
                                        <tr key={index} onClick={() => { handleEditClick(vendorItem); }}>
                                            <td>{vendorItem.item.id}</td>
                                            <td>{vendorItem.item.name}</td>
                                            <td>{vendorItem.isSameItemVendorClient ? '' : vendorItem.itemSale.name}</td>
                                            <td>${vendorItem.cost.toFixed(2)}</td>
                                            <td>
                                                {vendorItem.currency?.name}
                                            </td>
                                            <td>
                                                <Form.Check
                                                    defaultChecked={!vendorItem.isSameItemVendorClient}
                                                    disabled={true}
                                                    className='text-center'
                                                />
                                            </td>
                                            <td>
                                                <span>
                                                    <img
                                                        src={icono_editar}
                                                        alt='icon_edit'
                                                        onClick={() => {
                                                            handleEditClick(vendorItem);
                                                        }}
                                                    />
                                                    &nbsp;|&nbsp;
                                                    <img
                                                        src={icono_borrar}
                                                        alt=' icon_delete'
                                                        onClick={() => {
                                                            messages.showConfirmMessage(() => { return () => { handleDeleteClick(vendorItem); } });
                                                        }}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default Itemspervendor;