import React, { useState } from 'react';
import ConfirmMessage from 'components/Mensajes/ConfirmMessage';
import ErrorMessage from 'components/Mensajes/ErrorMessage';
import Loading from 'components/Mensajes/loading';

const UseMessages = () => {

    const [isMessageLoadingShow, setIsMessageLoadingShow] = useState(false);
    const [isMessageConfirmShow, setIsMessageConfirmShow] = useState(false);
    const [callback, setCallback] = useState(null);
    const [isMessageErrorShow, setIsMessageErrorShow] = useState(false);
    const [messageErrorText, setMessageErrorText] = useState('');

    const showLoadingMessage = () => {
        setIsMessageLoadingShow(true);
    }

    const hideLoadingMessage = () => {
        setIsMessageLoadingShow(false);
    }

    const showConfirmMessage = (functionToExecute) => {
        if (functionToExecute === undefined) throw new Error("param 'functionToExecute' is required");
        if (typeof functionToExecute !== 'function') throw new Error("param 'functionToExecute' should be a function references");

        setCallback(functionToExecute);
        setIsMessageConfirmShow(true);
    }

    const showErrorMessage = (ErrorMessage) => {
        if (ErrorMessage === undefined) throw new Error("param 'errorMessage' is required");
        if (typeof ErrorMessage !== 'string') throw new Error("param 'ErrorMessage' should be a string");

        setMessageErrorText(ErrorMessage);
        setIsMessageErrorShow(true);
    }

    const messagesHTMLElement = (
        <>
            {
                isMessageLoadingShow && (<Loading />)
            }
            {
                isMessageErrorShow && (<ErrorMessage Message={messageErrorText} onCloseClick={() => { setIsMessageErrorShow(false); }} />)
            }
            {
                isMessageConfirmShow && (<ConfirmMessage Close={() => { setIsMessageConfirmShow(false); }} onConfirm={callback} />)
            }
        </>
    );

    return {
        messagesHTMLElement,
        showLoadingMessage,
        hideLoadingMessage,
        /**
         * parameter 'functionToExecute' required a clouser. Example: objMessages.ShowConfirmMessage(()=>{ return ()=>{ doSomething(); }})
         */
        showConfirmMessage,
        showErrorMessage
    }
}

export default UseMessages;