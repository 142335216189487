import { api_endpoints } from "Global/Globals";
import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get('token');

export async function ViewModel() {
    try {
        const { data } = await axios.post(api_endpoints.get_itembase_viewmodel, {}, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        console.log(error.message);
        return null;
    }
}

export async function Save(itembase) {
    try {
        await axios.post(api_endpoints.save_itembase, itembase, { headers: { 'Authorization': token, 'Content-Type': 'application/json' } })
        return true;
    } catch (error) {
        console.error(error.message);
        return null;
    }
}

export async function Delete(itembase_id) {
    try {
        const { data } = axios.post(api_endpoints.delete_itembase, { id: itembase_id }, { headers: { 'Authorization': token, 'Content-Type': 'application/json' } });
        return true;
    } catch (error) {
        console.error(error);
        return null;
    }
}