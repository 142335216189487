import UseMessages from 'components/Mensajes/messages_section';
import Layout from 'components/General/layout';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row, Form, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { AddItemToBranch, AssociateItemBranchViewModel, RemoveItemToBranch } from 'Data/Clients/clients';
import { icono_borrar } from 'Assets/Images/images';

const AssociateItemsBranch = () => {
    const messages = UseMessages();
    const messagesHTMLElement = messages.messagesHTMLElement;
    const { clientId } = useParams();
    const navigate = useNavigate();
    const [viewModel, setViewModel] = useState(null);
    const [itemData, setItemData] = useState({
        id: 0,
        itemId: 0,
        clientId: clientId,
        branchId: 0,
        price: 0
    })

    const loadviewModel = async () => {
        messages.showLoadingMessage();
        const _viewModel = await AssociateItemBranchViewModel(clientId);
        setViewModel(_viewModel);
        console.log(_viewModel);
        messages.hideLoadingMessage();
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target;

        setItemData(prevData => ({ ...prevData, [name]: value }));
    }

    const handleOnClickBack = () => { navigate('/associateitems'); }

    const handleSaveClick = () => {
        const itemToSave = {
            id: itemData.id,
            branchId: itemData.branchId,
            itemId: itemData.itemId,
            price: itemData.price,
        }

        messages.showConfirmMessage(() => {
            return async () => {
                messages.showLoadingMessage();
                const isok = await AddItemToBranch(itemToSave);
                if (isok) loadviewModel();
                messages.hideLoadingMessage();
            }
        })
    }

    const handleDeleteClick = useCallback((item) => {
        messages.showConfirmMessage(() => {
            return async () => {
                messages.showLoadingMessage();
                const isok = await RemoveItemToBranch(item.id);
                if (isok) loadviewModel();
                messages.hideLoadingMessage();
            }
        }, [])
    })

    const handleEditClick = useCallback((item) => {
        setItemData(prevData => ({
            ...prevData,
            id: item.id,
            price: item.price,
            itemId: item.item.id,
        }))
    }, [])

    const handleNewClick = () => {
        setItemData({
            id: 0,
            itemId: 0,
            clientId: clientId,
            branchId: 0,
            price: 0
        })
    }

    useEffect(() => {
        loadviewModel();
    }, [])

    return (
        <>
            {messagesHTMLElement}
            <Layout>
                <h4>Asociar Producto a sucursal</h4>
                <p className='text-gray-400'>
                    Modulo para asociar productos a sucursal. La sucursal ignorara los productos dados por el cliente.
                </p>
                <hr />
                <Button
                    variant='secondary'
                    onClick={handleOnClickBack}
                >
                    Regresar a Cliente
                </Button>
                <hr />
                <Form.Select
                    className='text-center'
                    value={itemData.branchId}
                    name='branchId'
                    onChange={handleOnChange}
                >
                    <option value={0}>Selecciona sucursal...</option>
                    {
                        viewModel?.branches?.map(branch => (
                            <option key={branch.id} value={branch.id}>{branch.name}</option>
                        ))
                    }
                </Form.Select>
                <Row className='mt-0 mt-md-4'>
                    <Col xl={3}>
                        <Row>
                            <Col sm={12}>
                                <Form.Label>Producto</Form.Label>
                                <Form.Select
                                    className='text-center'
                                    value={itemData.itemId}
                                    name='itemId'
                                    onChange={handleOnChange}
                                >
                                    <option value={0}>Seleccionar...</option>
                                    {
                                        viewModel?.items?.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                            <Col sm={12} className='mt-2'>
                                <Form.Label>Precio</Form.Label>
                                <Form.Control
                                    className='text-center'
                                    type='number'
                                    value={itemData.price}
                                    name='price'
                                    onChange={handleOnChange}
                                />
                            </Col>
                        </Row>
                        <Button
                            variant='success mt-3'
                            onClick={handleSaveClick}
                        >
                            Guardar
                        </Button>
                        <Button
                            variant='secondary mt-3 ml-3'
                            onClick={handleNewClick}
                        >
                            Nuevo
                        </Button>
                    </Col>
                    <Col className='border-l border-l-1 border-l-zinc-500 mt-3 mt-xl-0'>
                        <Table className='GenericTable'>
                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th>Precio</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {
                                    viewModel?.branches.find(b => b.id === Number(itemData.branchId))?.items?.map(item => (
                                        <tr key={item.id} onClick={() => handleEditClick(item)}>
                                            <td>{item.item.name}</td>
                                            <td>$ {item.price}</td>
                                            <td>
                                                <img src={icono_borrar} onClick={() => handleDeleteClick(item)} />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Layout>
        </>
    );
}

export default AssociateItemsBranch;