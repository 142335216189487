import React, { useEffect, useRef, useState } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
// Imagenes
import ROCA_Logo from '../../Assets/Images/freshco-blanco.png';
import iconoAnonimo from '../../Assets/Images/perfil_testUsuario2.png';
import iconoNotificacion from '../../Assets/Images/icono_notificacion.png';
import './styles/layout.css';
import { getImageProfile, getMenuPermissions, getTheme, logout, setTheme, setimageprofile, validateToken } from 'Data/Employees/employeesData';
import UseMessages from 'components/Mensajes/messages_section';

function Layout(props) {
  const [fetchData, setFetchData] = useState([]);
  const messages = UseMessages();
  const htmlElementMessages = messages.messagesHTMLElement;
  const [isMessageLoadingShow, setisMessageLoadingShow] = useState(false);
  const navigate = useNavigate();
  const user_cookie = Cookies.get('UserInfo');
  const user_json = user_cookie ? JSON.parse(user_cookie) : "";
  const [greetings_msg, setGreetingsMsg] = useState('');
  const [user_icon, setUserIcon] = useState(null);
  const fileRef = useRef(null);
  const [is_dark_theme, setDarkTheme] = useState(null);

  const HandleLogOutClick = async () => {
    await logout();
    Cookies.remove('token');
    Cookies.remove('UserInfo');
    navigate('/', { replace: true });
  };

  const HandleMenuRedirect = (menuToGo) => navigate(menuToGo, { replace: true });

  const Layout_Load = async () => {
    setisMessageLoadingShow(true);
    const data_permissions = await getMenuPermissions();
    if (!data_permissions) {
      Cookies.remove('token');
      Cookies.remove('userInfo');
      navigate('/', { replace: true });
    }
    setFetchData(data_permissions);
    setisMessageLoadingShow(false);
  }

  const setGreetingsMessage = () => {
    const actual_hour = new Date().getHours();

    if (actual_hour < 12) {
      setGreetingsMsg('Buenos días');
    }
    if (actual_hour > 11) {
      setGreetingsMsg('Buenas tardes');
    }
    if (actual_hour > 19) {
      setGreetingsMsg('Buenas noches');
    }
  }

  const fetchUserImage = async () => {
    const profileImage = await getImageProfile();

    if (profileImage || profileImage instanceof Blob) {
      const url = URL.createObjectURL(profileImage);
      setUserIcon(url);
    }
  }

  const handleButtonImageClick = () => {
    fileRef.current.click();
  }

  const handleImageChange = async (event) => {
    const image_file = event.target.files[0];
    const isOk = await setimageprofile(image_file);
    if (isOk) window.location.reload();
  }

  const changeTheme = async () => {
    const isOk = await setTheme(!is_dark_theme);
    if (isOk) {
      window.location.reload();
    }
  }


  useEffect(async () => {
    document.title = 'SIFFS';
    document.body.style.backgroundColor = "white";
    document.body.style.backgroundImage = "";
    const isValidToken = await validateToken();
    if (!isValidToken) { navigate('/login', { replace: true }); }

    const theme = await getTheme();
    setDarkTheme(theme === 'dark');

    Layout_Load();
    setGreetingsMessage();
    fetchUserImage();
  }, []);

  useEffect(() => {
    messages.showLoadingMessage();
    const get_tables = document.querySelectorAll('.GenericTable');
    if (is_dark_theme) {
      document.body.style.backgroundColor = "rgb(71, 71, 91)";

      get_tables.forEach(t => {
        t.classList.add('black');
      })
    }
    else {
      document.body.style.backgroundColor = "white";

      get_tables.forEach(t => {
        t.classList.remove('black');
      })
    }
    messages.hideLoadingMessage();
  }, [is_dark_theme])


  return (
    <>
      {htmlElementMessages}
      <input type='file' accept='image/*' ref={fileRef} className='d-none' onChange={handleImageChange} />
      <Navbar collapseOnSelect expand="lg" className="navbar-custom" bg="light" variant="dark">
        <Container>
          <Navbar.Brand onClick={() => HandleMenuRedirect('/Home')}>
            <img alt="Logo" src={ROCA_Logo} className="d-inline-block align-top" />
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              {/* Section menus*/}
              {!isMessageLoadingShow && (
                <>
                  {fetchData.map((menu, index) => (
                    <NavDropdown
                      key={index}
                      title={menu.parent.text}
                      id={'menu-dropdown-' + index} >
                      {menu.children.map((submenu, subIndex) => (
                        <NavDropdown.Item
                          key={subIndex}
                          title={submenu.text}
                          href={submenu.url}
                        >
                          {submenu.text}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  ))}
                </>
              )}
              <NavDropdown
                className='d-block d-lg-none'
                title={'Mi Cuenta'}
              >
                <NavDropdown.Item>Rembolsos</NavDropdown.Item>
                <NavDropdown.Item onClick={handleButtonImageClick}>Cambiar foto</NavDropdown.Item>
                <NavDropdown.Item
                  onClick={changeTheme}
                >
                  Cambiar tema
                </NavDropdown.Item>
                <NavDropdown.Item
                  onClick={HandleLogOutClick}
                  className='text-danger'
                >
                  Cerrar sesión
                </NavDropdown.Item>
              </NavDropdown>
              {/* End section */}
              {/* Section User options */}
              {!isMessageLoadingShow && (
                <Nav className="userOptions ml-auto d-none d-lg-flex">
                  <NavDropdown
                    title={
                      <>
                        <span className='mx-md-3 d-none d-lg-inline'>{greetings_msg}, {user_json.firstName}</span>
                        <img src={user_icon ?? iconoAnonimo} alt="iconPerfil" className="iconP d-inline-block" />
                      </>
                    }
                    drop='start'
                    id="basic-nav-dropdown"
                    className='mr-5'
                  >
                    <NavDropdown.Item onClick={handleButtonImageClick}>Cambiar foto</NavDropdown.Item>
                    <NavDropdown.Item>Rembolsos</NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={changeTheme}
                    >
                      Cambiar tema
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      onClick={HandleLogOutClick}
                      className='text-danger'
                    >
                      Cerrar sesión
                    </NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown
                    title={
                      <img src={iconoNotificacion} alt="iconNotificacion" width={30} height={30} className="iconN d-inline-block" />
                    }
                    id="basic-nav-dropdown-noti"
                  >
                    {/** ToDo: puts the notificacion map */}
                  </NavDropdown>
                </Nav>
              )}
              {/* End section */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container fluid className={`infoContainers${is_dark_theme ? '-black' : ''}`}>
        {props.children}
      </Container>
    </>
  );
}

export default Layout;
