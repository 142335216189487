import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';

function ErrorMessage({ Message, onCloseClick }) {

    const btnRef = useRef(null);

    const style = {
        height: (window.innerHeight > document.body.scrollHeight) ? window.innerHeight : document.body.scrollHeight,
        width: ((window.innerWidth > document.body.scrollWidth) ? window.innerWidth : document.body.scrollWidth),
        zIndex: 2
    }

    useEffect(() => {
        btnRef.current.focus();
    }, [])

    return (
        <>
            <div className='waitFormContainerFull fadeIn' style={style}>
                <div className={`waitForm`}>
                    <br />
                    <h4 style={{ color: '#FB4646', textShadow: '0px 0px 3px black' }}>¡Error!</h4>
                    <center>{Message}</center>
                    <br />
                    <Button
                        ref={btnRef}
                        variant='light'
                        onClick={() => { onCloseClick() }}
                    >
                        Cerrar
                    </Button>
                    <br />
                </div>
            </div>
        </>
    );
}

export default ErrorMessage;