import UseMessages from 'components/Mensajes/messages_section';
import Layout from 'components/General/layout';
import React, { useEffect, useState } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ClientBlock, ClientlistViewModel } from 'Data/Clients/clients';
import { icono_cancelar, icono_editar } from 'Assets/Images/images';

const ClientsList = () => {
    const navigate = useNavigate();
    const messages = UseMessages();
    const messagesHTMLElement = messages.messagesHTMLElement;
    const [viewModel, setViewModel] = useState();


    const handleOnClickBlock = async (client) => {
        messages.showConfirmMessage(() => {
            const _client = client;
            return async () => {
                messages.showLoadingMessage();
                const isok = await ClientBlock(_client);
                if (isok) window.location.reload();
                messages.hideLoadingMessage();
            }
        })
    }

    const handleOnClickEdit = (client) => {
        navigate(`/clients/${client.id}`);
    }

    useEffect(async () => {
        messages.showLoadingMessage();
        document.title = 'SIFFS - Lista clientes';
        const _viewmodel = await ClientlistViewModel();
        setViewModel(_viewmodel);
        messages.hideLoadingMessage();
    }, [])

    return (
        <Layout>
            {messagesHTMLElement}
            <h4>lista de Clientes</h4>
            <p className='text-zinc-400'>lista de clientes registrados en sistema</p>
            <hr />
            <Button
                variant='secondary'
                onClick={() => { navigate('/clients'); }}
            >
                Ir a alta clientes
            </Button>
            <hr />
            <div>
                <Table className='GenericTable overedflow'>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>Nombre</th>
                            <th>Razon social</th>
                            <th>RFC</th>
                            <th>Bloqueado</th>
                            <th>Opciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            viewModel?.clients?.map(client => (
                                <tr key={client.id} className='text-center'>
                                    <td>{client.id}</td>
                                    <td>{client.name}</td>
                                    <td>{client.legalName}</td>
                                    <td>{client.rfc}</td>
                                    <td><Form.Check id={`${client.id}clientlocked`} defaultChecked={!client.active} disabled /></td>
                                    <td>
                                        <img
                                            src={icono_editar}
                                            onClick={() => { handleOnClickEdit(client); }}
                                        />
                                        &nbsp;|&nbsp;
                                        <img
                                            src={icono_cancelar}
                                            onClick={() => { handleOnClickBlock(client); }}
                                        />
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </div>
        </Layout>
    );
}

export default ClientsList;