const Globals = {
    UrlLocal: 'https://localhost:7218/',
    UrlProd: 'https://api.freshcomexico.com/',
    UrlLocalFront: 'https://localhost:44439/',
    Urllocaltest: 'http://192.168.20.110:9095/'

}

export const UrlApi = process.env.NODE_ENV === 'production' ? Globals.UrlProd : Globals.UrlLocal;

export const api_endpoints = {
    validate_token: `${UrlApi}employees/validatetoken`,
    get_permissions: `${UrlApi}employees/permissions/menus/get`,
    login: `${UrlApi}employees/login`,
    logout: `${UrlApi}employees/logout`,
    currency_get: `${UrlApi}items/getcurrencies`,
    purchase_order_save: `${UrlApi}purchase/savepurchase`,
    purchase_order_get: `${UrlApi}purchase/getpurchase`,
    purchase_order_details_get: `${UrlApi}purchase/getpurchasedetails`,
    get_theme: `${UrlApi}employees/profile/theme`,
    set_theme: `${UrlApi}employees/profile/theme/set`,
    get_profile_image: `${UrlApi}employees/profile/image`,
    set_profile_image: `${UrlApi}employees/profile/image/set`,
    get_employees_viewmodel: `${UrlApi}employees/viewmodel`,
    save_employees: `${UrlApi}employees/save`,
    save_employees_permissions: `${UrlApi}employees/permissions/save`,
    get_itembase_viewmodel: `${UrlApi}items/base/viewmodel`,
    save_itembase: `${UrlApi}items/base/save`,
    delete_itembase: `${UrlApi}items/base/delete`,
    get_presentations_viewmodel: `${UrlApi}items/presentations/viewmodel`,
    save_presentation: `${UrlApi}items/presentations/save`,
    delete_presentation: `${UrlApi}items/presentations/delete`,
    get_items_viewmodel: `${UrlApi}items/viewmodel`,
    save_items: `${UrlApi}items/save`,
    delete_items: `${UrlApi}items/delete`,
    get_warehouses_viewmodel: `${UrlApi}warehouses/viewmodel`,
    save_warehouse: `${UrlApi}warehouses/save`,
    delete_warehouse: `${UrlApi}warehouses/delete`,
    get_inventories_viewmodel: `${UrlApi}inventories/viewmodel`,
    save_inventorie: `${UrlApi}inventories/save`,
    delete_inventorie: `${UrlApi}inventories/get`,
    get_vendors_viewmodel: `${UrlApi}vendors/viewmodel`,
    save_vendors: `${UrlApi}vendors/save`,
    delete_vendors: `${UrlApi}vendors/get`,
    get_vendorItems_viewmodel: `${UrlApi}vendors/items/viewmodel`,
    add_vendorItem: `${UrlApi}vendors/items/add`,
    remove_vendorItem: `${UrlApi}vendors/items/remove`,
    get_inventorytransactions_viewmodel: `${UrlApi}inventories/transactions/viewmodel`,
    save_inventorytransaction: `${UrlApi}inventories/transactions/save`,
    delete_inventorytransaction: `${UrlApi}inventories/transactions/cancel`,
    get_kardex_viewmodel: `${UrlApi}inventories/kardex/viewmodel`,
    get_kardex_data: `${UrlApi}inventories/kardex/get`,
    get_purchases_viewmodel: `${UrlApi}purchases/viewmodel`,
    save_purchase: `${UrlApi}purchases/save`,
    delete_purchase: `${UrlApi}purchases/delete`,
    get_purchasesList_viewmodel: `${UrlApi}purchases/list/viewmodel`,
    get_clients_viewmodel: `${UrlApi}clients/viewmodel`,
    save_client: `${UrlApi}clients/save`,
    Lock_client: `${UrlApi}clients/lock`,
    get_clientsOrders_viewmodel: `${UrlApi}clients/orders/viewmodel`,
    get_clientList_viewmodel: `${UrlApi}clients/list/viewmodel`,
    get_client: `${UrlApi}clients/get`,
    save_corporative: `${UrlApi}clients/corporatives/save`,
    get_branches_viewmodel: `${UrlApi}clients/branches/viewmodel`,
    save_branch: `${UrlApi}clients/branches/save`,
    delete_branch: `${UrlApi}clients/branches/delete`,
    get_branch: `${UrlApi}clients/branches/get`,
    get_branchesList_viewmodel: `${UrlApi}clients/branches/list/viewmodel`,
    get_associateitem_viewmodel: `${UrlApi}clients/associateitems/viewmodel`,
    add_associateitem_to_client: `${UrlApi}clients/associateitems/add`,
    remove_associateitem_to_client: `${UrlApi}clients/associateitems/remove`,
    get_associateitem_branch_viewmodel: `${UrlApi}clients/associateitems/branches/viewmodel`,
    add_associateitem_to_branch: `${UrlApi}clients/associateitems/branches/add`,
    remove_associateitem_to_branch: `${UrlApi}clients/associateitems/branches/remove`,
}
















export const MessagesText = {
    generalError: 'Error durante el proceso. Favor de contactar al administrador del sitio'
}



export const userHasPermission = async ({ sysSection, Token }) => {
    let result = null;
    const url = `${UrlApi}security/isAuthorized?SystSec_Id=${sysSection}&Empl_Token=${Token}`;
    const response = await fetch(url);
    result = response.ok ?? null;

    if (result === null)
        console.error('The request to server doesn\'t returned a correct format o it response null.');

    return result;
}


export const tablaAArrayDeObjetos = (table_id) => {

    if (!table_id) throw new Error("paramater table_id is required");

    const tabla = document.getElementById(table_id);
    const arrayDeObjetos = [];

    for (let i = 1; i < tabla.rows.length; i++) {
        const nombresColumnas = [];
        const cabecera = tabla.rows[0];
        for (let i = 0; i < cabecera.cells.length; i++) {
            nombresColumnas.push(cabecera.cells[i].innerText);
        }

        for (let i = 1; i < tabla.rows.length; i++) {
            const fila = tabla.rows[i];
            const objeto = {};

            for (let j = 0; j < fila.cells.length; j++) {
                objeto[nombresColumnas[j]] = fila.cells[j].innerText;
            }

            arrayDeObjetos.push(objeto);
        }
    }

    return arrayDeObjetos;
}