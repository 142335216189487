import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../../components/General/layout';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import './vegetables.css';

//Imagenes
import { icono_cancelar, icono_editar } from '../../../Assets/Images/images';

import { CSVLink } from "react-csv";
import { excel_icon } from 'Assets/Images/images';
import UseMessages from 'components/Mensajes/messages_section';
import { Delete, Save, ViewModel } from 'Data/items/itemsBaseData';

const Vegetables = () => {
    const messages = UseMessages();
    const htmlElementMessages = messages.messagesHTMLElement;
    const [viewModel, setViewModel] = useState(null);
    const [vegetableData, setVegetableData] = useState({ vege_Id: null, vege_Name: '' });
    const tableRef = useRef(null);

    const fetchItemBaseList = async () => {
        const data = await ViewModel();
        if (data) { setViewModel(data) }
    }

    const handleforminputChange = event => {
        const { name, value } = event.target;

        setVegetableData(prevdata => (
            {
                ...prevdata,
                [name]: value,
            }
        ))
    }

    const handleNewClick = () => {
        setVegetableData({
            vege_Id: null,
            vege_Name: ''
        });
    }

    const handleDeleteClick = async (vegetable) => {
        messages.showConfirmMessage();
        const { vege_Id } = vegetable;
        await Delete(vege_Id);
    }

    const handleEditClick = (vegetable) => {
        const { id, name } = vegetable;

        setVegetableData({
            ...vegetableData,
            vege_Id: id,
            vege_Name: name
        })

    }

    const handleSaveClick = async () => {
        if (vegetableData.vege_Name === '' && (vegetableData.vege_Id === null || vegetableData === '')) {
            messages.showErrorMessage('Debe llenar todos los campos primero');
            return;
        }

        messages.showLoadingMessage();
        const itemBase = {
            id: (vegetableData.vege_Id === '' ? 0 : vegetableData.vege_Id) ?? 0,
            name: vegetableData.vege_Name,
            active: true
        };

        const isOk = await Save(itemBase);
        if (isOk) window.location.reload();
    }

    useEffect(() => {
        document.title = 'SIFFS - Vegetales';
// const dataExcel = func("id_table");
        fetchItemBaseList();
    }, []);

    return (
        <Layout>
            {htmlElementMessages}
            <>
                <h4>Producto base</h4>
                <p className='Containerdescripcion'>
                    Modulo para la gestión de producto base en sistema
                </p>
                <hr />
                <Row>
                    <Col lg={3}>
                        <Form className='FormVegetables'>
                            <Form.Label>ID</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Id'
                                name='vege_Id'
                                value={vegetableData.vege_Id ?? ''}
                                onChange={e => handleforminputChange(e)}
                            />
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Nombre base'
                                name='vege_Name'
                                value={vegetableData.vege_Name ?? ''}
                                onChange={e => handleforminputChange(e)}
                            />
                            <br />
                            <Button
                                variant='success'
                                onClick={() => { messages.showConfirmMessage(() => { return () => { handleSaveClick(); } }) }}
                            >
                                Guardar
                            </Button>
                            &nbsp;
                            <Button
                                variant='secondary'
                                onClick={handleNewClick}
                            >
                                nuevo
                            </Button>

                        </Form>
                    </Col>
                    <Col lg={9} className='GridSection'>
                        <CSVLink
                            data={[]}
                            filename='vegetables-grid'
                            className='no-underline'
                        >
                            <Button variant='success my-3 d-block'>
                                <img src={excel_icon} width={20} height={20} className='d-inline-block mr-2' />
                                <span >Exportar</span>
                            </Button>
                        </CSVLink>
                        <Table
                            className='GenericTable text-center'
                            id='vegetables-list-table'
                            ref={tableRef}
                            hover={true}
                            striped={true}
                        >
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    viewModel?.itembases?.map((vegetable, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => { handleEditClick(vegetable); }}
                                        >
                                            <td>{vegetable.id}</td>
                                            <td>{vegetable.name}</td>
                                            <td>
                                                <span
                                                    onClick={() => { handleEditClick(vegetable); }}
                                                >
                                                    <img src={icono_editar} alt='Icon_Edit' />
                                                </span>
                                                &nbsp;|&nbsp;
                                                <span
                                                    href=''
                                                    onClick={() => {
                                                        messages.showConfirmMessage(() => { return () => { handleDeleteClick(vegetable); } });
                                                    }}
                                                >
                                                    <img src={icono_cancelar} alt='Icon_Del' />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        </Layout >
    );
}


export default Vegetables;