import { api_endpoints } from "Global/Globals";
import axios from "axios";
import cookies from "js-cookie";

const token = cookies.get('token');

export async function ViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_vendors_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        if (error.message) {
            console.log(error.message);
        } else {
            console.log(error);
        }
        return null
    }
}

export async function Save(vendor) {
    try {
        await axios.post(api_endpoints.save_vendors, vendor, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        if (error.message) {
            console.log(error.message);
        } else {
            console.log(error);
        }
        return false
    }
}

export async function Delete(vendor) {
    try {
        await axios.post(api_endpoints.delete_vendors, vendor, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        if (error.message) {
            console.log(error.message);
        } else {
            console.log(error);
        }
        return false
    }
}

export async function IViewModel(vend_id) {
    try {
        const { data } = await axios.get(api_endpoints.get_vendorItems_viewmodel + `/${vend_id}`, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        if (error.message) {
            console.log(error.message);
        } else {
            console.log(error);
        }
        return null
    }
}

export async function VendorItemAdd(vendor_item) {
    try {
        await axios.post(api_endpoints.add_vendorItem, vendor_item, { headers: { 'Authorization': token, 'Content-Type': 'application/json' } });
        return true;
    } catch (error) {
        if (error.message) {
            console.log(error.message);
        } else {
            console.log(error);
        }
        return false
    }
}

export async function VendorItemRemove(vendor_item) {
    try {
        await axios.get(api_endpoints.remove_vendorItem + `/${vendor_item.id}`, { headers: { 'Authorization': token, 'Content-Type': 'application/json' } });
        return true;
    } catch (error) {
        if (error.message) {
            console.log(error.message);
        } else {
            console.log(error);
        }
        return false
    }
}