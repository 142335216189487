import React, { useCallback, useEffect, useState } from 'react';
import './pedidos.css';
import { excel_icon } from 'Assets/Images/images';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import Layout from 'components/General/layout';
import UseMessages from 'components/Mensajes/messages_section';
import { OrdersViewModel } from 'Data/Clients/clients';
import { Log } from 'oidc-client';

const Pedidos = () => {
    const messages = UseMessages();
    const messagesHTMLElement = messages.messagesHTMLElement;
    const [viewModel, setViewModel] = useState();
    const [itemsDisplayed, setItemsDisplayed] = useState([]);
    const [orderData, setOrderData] = useState({
        ceDiId: 0,
        clientId: 0,
        branchId: 0,
        items: []
    });

    const handleOnChange = (e) => {
        const { value, name } = e.target;

        setOrderData(prevData => ({ ...prevData, [name]: value }));

        if (name === 'branchId') {
            const branchItemList = viewModel?.clients?.find(c => c.id === Number(orderData.clientId))?.branches?.find(b => b.id === Number(value))?.items;

            if (value === '0') {
                setItemsDisplayed([]);
            }
            else if (branchItemList?.length > 0) {
                setItemsDisplayed(branchItemList);
            }
            else {
                setItemsDisplayed(viewModel?.clients?.find(c => c.id === Number(orderData.clientId))?.items ?? []);
            }
        }
    }

    const handleQuantityChange = (e) => {
        const { value } = e.target;
        const lastData = orderData.items.slice();

        setOrderData(prevData => ({

        }))
    }

    const handleOnClickNew = useCallback(() => {
        setOrderData({
            ceDiId: 0,
            clientId: 0,
            branchId: 0
        });

        setItemsDisplayed([]);
    }, [])

    useEffect(async () => {
        messages.showLoadingMessage();
        const _viewModel = await OrdersViewModel();
        setViewModel(_viewModel);
        console.log(_viewModel);
        messages.hideLoadingMessage();
    }, [])

    return (
        <Layout>
            {messagesHTMLElement}
            <>
                <h4 className='h4'>Pedidos</h4>
                <p className='Containerdescripcion'>
                    Seccion para gestionar los pedidos de los clientes.
                </p>
                <hr />
                <Button variant='secondary'>Lista de pedidos</Button>
                <Button variant='success ml-3'>
                    <img src={excel_icon} width={20} height={20} className='mx-2 inline-block' />
                    <span>Cargar Excel</span>
                </Button>
                <hr />
                <Row>
                    <Col md={10} className='p-0 p-md-3'>
                        <h5 className='Containerdescripcion mb-3'>General</h5>
                        <Form className=''>
                            <Row className='w-100 d-flex justify-content-center ml-md-5 mt-3'>
                                <Col xs={12} lg={3}>
                                    <Form.Label>Región</Form.Label>
                                    <Form.Select
                                        className='d-inline-block'
                                        value={orderData.ceDiId}
                                        name='ceDiId'
                                        onChange={handleOnChange}
                                    >
                                        <option value={0}>Region...</option>
                                        {
                                            viewModel?.ceDis?.map(cedi => (
                                                <option key={cedi.id} value={cedi.id}>{cedi.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Col>
                                <Col xs={12} lg={3}>
                                    <Form.Label>Cliente</Form.Label>
                                    <Form.Select
                                        className='d-inline-block'
                                        value={orderData.clientId}
                                        name='clientId'
                                        onChange={handleOnChange}
                                    >
                                        <option value={0}>Cliente...</option>
                                        {
                                            viewModel?.clients?.map(client => (
                                                <option key={client.id} value={client.id}>{client.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Col>
                                <Col xs={12} lg={3}>
                                    <Form.Label>Sucursal</Form.Label>
                                    <Form.Select
                                        className='d-inline-block ml-md-5 mt-3 mt-md-0'
                                        value={orderData.branchId}
                                        name='branchId'
                                        onChange={handleOnChange}
                                    >
                                        <option value={0}>Sucursal...</option>
                                        {
                                            viewModel?.clients.filter(c => c.id === Number(orderData.clientId))[0]?.branches?.filter(b => b.ceDiId === Number(orderData.ceDiId)).map(branch => (
                                                <option key={branch.id} value={branch.id}>{branch.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Col>
                                <Col xs={12} lg={3}>
                                    <Form.Label className='text-nowrap'>Orden de compra</Form.Label>
                                    <Form.Control
                                        type='text'
                                        className='d-inline-block ml-md-5 mt-3 mt-md-0'
                                        placeholder='Orden de Compra'
                                    />
                                </Col>
                            </Row>
                            <Row className='w-100 d-flex justify-content-center mt-2 mt-md-5'>
                                <Col xs={12} lg={3}>
                                    <Form.Label>Fecha de Entrega</Form.Label>
                                    <Form.Control
                                        type='date'
                                        className='d-inline-block ml-0 ml-md-5'
                                        placeholder='Orden de Compra Externa'
                                    />
                                </Col>
                            </Row>
                        </Form>
                        <hr />
                        <h5 className='Containerdescripcion my-4'>Productos</h5>
                        <Table className='GenericTable'>
                            <thead>
                                <tr>
                                    <th>Producto</th>
                                    <th>Precio</th>
                                    <th>Cantidad</th>
                                    <th>Importe</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {
                                    itemsDisplayed?.map(item => (
                                        <tr key={item.id}>
                                            <td>{item.item.name}</td>
                                            <td>$ {item.price}</td>
                                            <td>
                                                <Form.Control
                                                    type='number'
                                                    className='text-center'
                                                />
                                            </td>
                                            <td>$</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <hr />
                    </Col>
                    <Col md={2} className='flex flex-column align-items-center border-1 border-zinc-400'>
                        <Button className='mt-3 w-28' onClick={handleOnClickNew}>Nuevo</Button>
                        <Button variant='success' className='mt-3 w-28'>Guardar</Button>

                        <h5 className='mt-5'>Pedido:</h5>
                        <span>0</span>

                        <h5 className='mt-5'>Total:</h5>
                        <span>$0.00</span>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default Pedidos;