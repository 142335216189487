import { api_endpoints } from "Global/Globals";
import axios from "axios";
import cookies from "js-cookie";

const token = cookies.get('token');

export async function ViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_inventories_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        if (error.message) { console.error(error.message); }
        else { console.error(error); }
        return null;
    }
}

export async function Save(inventory) {
    try {
        await axios.post(api_endpoints.save_inventorie, inventory, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        if (error.message) { console.error(error.message); }
        else { console.error(error); }
        return null;
    }
}

export async function Delete(inventory) {
    try {
        await axios.post(api_endpoints.delete_inventorie, inventory, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        if (error.message) { console.error(error.message); }
        else { console.error(error); }
        return null;
    }
}

// Inventory transactions

export async function TViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_inventorytransactions_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        if (error.message) { console.error(error.message); }
        else { console.error(error); }
        return null;
    }
}

export async function TSave(transaction) {
    try {
        await axios.post(api_endpoints.save_inventorytransaction, transaction, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        console.log(error.response);
        return false;
    }
}

export async function TCancel(transaction) {
    try {
        await axios.post(api_endpoints.delete_inventorytransaction, transaction, { headers: { 'Authorization': token } });
        return true;
    } catch (error) {
        if (error.message) { console.error(error.message); }
        else { console.error(error); }
        return null;
    }
}

// Kardex

export async function KXViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_kardex_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        if (error.message) { console.error(error.message); }
        else { console.error(error); }
        return null;
    }
}

export async function getKardexData(Filter) {
    try {
        const { data } = await axios.post(api_endpoints.get_kardex_data, Filter, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        if (error.message) { console.error(error.message); }
        else { console.error(error); }
        return null;
    }
}