import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Layout from 'components/General/layout';
import ConfirmMessage from 'components/Mensajes/ConfirmMessage';
import ErrorMessage from 'components/Mensajes/ErrorMessage';
import Loading from 'components/Mensajes/loading';

const StockOnHands = () => {
    const [messageErrorText, setMessageErrorText] = useState('');
    const [isMessageErrorShow, setIsMessageErrorShow] = useState(false);
    const [functionToExecute, setFunctionToExecute] = useState({ callback: () => { } });
    const [isMessageConfirmShow, setIsMessageConfirmShow] = useState(false);
    const [isMessageLoadingShow, setIsMessageLoadingShow] = useState(false);

    return (
        <Layout>
            {
                isMessageConfirmShow && (<ConfirmMessage Close={() => { setIsMessageConfirmShow(false); }} onConfirm={functionToExecute.callback} />)
            }
            {
                isMessageErrorShow && (<ErrorMessage Message={messageErrorText} onCloseClick={() => { setIsMessageErrorShow(false); }} />)
            }
            {
                isMessageLoadingShow && (<Loading />)
            }
            <>
                
            </>
        </Layout>
    );
}

export default StockOnHands;