import { api_endpoints } from "Global/Globals";
import axios from "axios";
import Cookies from "js-cookie";


const token = Cookies.get('token');

export async function ViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_presentations_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function Save(presentation) {
    if (!presentation) throw new Error("presentation is required.");

    try {
        const { status } = await axios.post(api_endpoints.save_presentation, presentation, { headers: { 'Authorization': token, 'Content-Type': 'application/json' } });
        return status === 200;
    } catch (error) {
        console.error(error.messages);
        return null;
    }
}

export async function Delete(presentation) {
    if (!presentation) throw new Error("presentation is required.");

    try {
        const { status } = await axios.post(api_endpoints.delete_presentation, presentation, { headers: { 'Authorization': token, 'Content-Type': 'application/json' } });
        return status === 200;
    } catch (error) {
        console.error(error.messages);
        return null;
    }
}