
import UseMessages from 'components/Mensajes/messages_section';
import Layout from 'components/General/layout';
import React, { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { BranchesListViewModel, DeleteBranch } from 'Data/Clients/clients';
import { icono_borrar, icono_editar } from 'Assets/Images/images';

const BranchesList = () => {
    const navigate = useNavigate();
    const { clientId } = useParams();
    const messages = UseMessages();
    const messagesHTMLElement = messages.messagesHTMLElement;
    const [viewModel, setViewModel] = useState(null);

    const handleOnClickEdit = (branch) => {
        navigate(`/clients/branches/${clientId}/${branch.id}`);
    }

    const handleOnClickDelete = (branch) => {
        messages.showConfirmMessage(() => {
            return async () => {
                messages.showLoadingMessage();
                const isok = await DeleteBranch(branch.id);
                if (isok) window.location.reload();
                messages.hideLoadingMessage();
            }
        })
    }

    useEffect(async () => {
        messages.showLoadingMessage();
        const _viewmodel = await BranchesListViewModel(clientId);
        setViewModel(_viewmodel);
        console.log(_viewmodel);
        messages.hideLoadingMessage();
    }, [])

    return (
        <Layout>
            {messagesHTMLElement}
            <h4>Lista de sucursales del cliente: </h4>
            <hr />
            <Button
                variant='secondary'
                onClick={() => { navigate('/clients/' + clientId); }}
            >
                Regresar
            </Button>
            <hr />
            <Table className='GenericTable text-center'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Nombre</th>
                        <th>Región / CeDi</th>
                        <th>Dirección</th>
                        <th>Contacto</th>
                        <th>Telefono</th>
                        <th>Email</th>
                        <th>Opciones</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        viewModel?.branches?.map(branch => (
                            <tr key={branch.id}>
                                <td>{branch.id}</td>
                                <td>{branch.name}</td>
                                <td>{branch.ceDi?.name}</td>
                                <td>{branch.address}</td>
                                <td>{branch.contactName}</td>
                                <td>{branch.contactPhone}</td>
                                <td>{branch.email}</td>
                                <td>
                                    <img src={icono_editar}
                                        onClick={() => { handleOnClickEdit(branch); }}
                                    /> &nbsp;| &nbsp;
                                    <img src={icono_borrar}
                                        onClick={() => { handleOnClickDelete(branch); }} />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
        </Layout>
    );
}

export default BranchesList;