import React, { useEffect, useState } from 'react';
import Layout from '../../components/General/layout';
import { freshco_logo_azul, freshco_logo_blanco_wallpaper } from 'Assets/Images/images';
import './home.css';
import { getTheme } from 'Data/Employees/employeesData';

const Home = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(async () => {
    const _isDarkTheme = await getTheme();
    setIsDarkTheme(_isDarkTheme === 'dark');
  }, [])

  return (
    <Layout>
      <>
        <h1>Bienvenido</h1>
        <hr />
        <p className='Containerdescripcion'>
          Sistema de gestión de recursos empresariales Freshco Food Service
        </p>
        <hr />
        <div className='flex flex-col justify-center align-items-center w-100 h-96'>
          <img src={isDarkTheme ? freshco_logo_blanco_wallpaper : freshco_logo_azul} className='logo-home d-block' />
          <p className='label-home mt-4'>¡Simpre Freshco!</p>
        </div>
      </>
    </Layout>
  );
}

export default Home;