import React, { useEffect, useRef, useState } from 'react';
import './transaction.css';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import Layout from 'components/General/layout';
import { icono_borrar } from 'Assets/Images/images';
import UseMessages from 'components/Mensajes/messages_section';
import { TSave, TViewModel } from 'Data/Inventories/InventoriesData';

const Transaction = () => {
    const [is_form_visible, setIsFormVisible] = useState(true);
    const [is_datatable_visible, setIsDataTableVisible] = useState(false);
    const [viewModel, setViewModel] = useState(null);

    const changeView = () => {
        setIsFormVisible(!is_form_visible);
        setIsDataTableVisible(!is_datatable_visible);
    }

    useEffect(async () => {
        document.title = 'SIFFS - Movimiento Inventario';
        const _viewmodel = await TViewModel();
        setViewModel(_viewmodel);
    }, [])

    return (
        <Layout>
            <>
                <h4>Registrar Movimiento de Inventarios</h4>
                <p className='Containerdescripcion'>
                    Modulo para registrar entradas y salidas de inventario
                </p>
                <hr />
                <Button
                    variant={!is_form_visible ? 'primary' : 'secondary'}
                    onClick={changeView}
                >
                    {!is_form_visible ? 'Nuevo' : 'Historico'}
                </Button>
                <hr />
                {
                    is_form_visible && (<FormTransaction _viewModel={viewModel} />)
                }
                {
                    is_datatable_visible && (<DataTableTransaction _viewModel={viewModel} />)
                }
            </>
        </Layout>
    );
}

const FormTransaction = ({ _viewModel }) => {
    const [inve_transaction, setInve_transaction] = useState({
        invetra_Id: 0,
        invetra_Type: 'E',
        invetra_Comments: '',
        inveTran_Datetime: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${(new Date().getDay() + 1).toString().padStart(2, '0')}`,
        invetra_Concept: 0,
        invetra_Ware_Id: 0,
        invetra_Inve_Id: 0,
        invetra_Vend_Id: 0,
        purcOrd_Id: 0,
        invetra_Invo_File: null,
        invetra_Vend_items: [],
    });
    const [itemToAdd, setItemToAdd] = useState({
        item_Id: 0,
        item_Name: '',
        item_Cost: 0,
        item_Quantity: 0
    })
    const [viewModel, setViewModel] = useState();
    const fileRef = useRef(null);
    const messages = UseMessages();
    const messagesHTMLElement = messages.messagesHTMLElement;


    const HandleFileInvoiceChange = (e) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            setInve_transaction(last_data => ({
                ...last_data,
                invetra_Invo_File: selectedFile
            }))
        }
    }

    const Delete_item_selected = (item) => {
        const { item_Id } = item;
        const item_list = inve_transaction.invetra_Vend_items.filter(i => i.item_Id !== Number(item_Id));

        setInve_transaction(lastData => ({
            ...lastData,
            invetra_Vend_items: item_list
        }));
    }

    const handleChange = (e, state = 'inve_transaction') => {
        const { name } = e.target;
        let { value } = e.target;

        if (name === 'invetra_Concept' && Number(value) === 8) {
            setInve_transaction(lastData => ({
                ...lastData,
                invetra_Vend_Id: 1 // ToDo: Hacer Rancho viejo el proveedor con el ID 1 
            }))
        }

        // Validates cost input has correct format.
        if (name === 'item_Cost') {
            value = value.replace('$', '');
            const isValidInput = /^(\d*\.?\d{0,4})$/.test(value);
            if (!isValidInput) return;
            for (let i = 0; i <= value.length; i++) {
                if (value[i] !== '0' || (value[i] === '0' && (i + 1) === value.length) || (value[i] === '0' && value[i + 1] === '.')) break;
                value = value.slice(i + 1, value.length);
            }
        }

        // Set the concept about the type transaction
        if (name === 'invetra_Type') {
            setInve_transaction(last_data => ({
                ...last_data,
                invetra_Concept: 0
            }))
        }

        // Resets the item that was selected to add in combobox
        if (name === 'invetra_Vend_Id') {
            setItemToAdd(lastData => ({
                ...lastData,
                item_Id: 0
            }));

            setInve_transaction((lastData) => ({ ...lastData, invetra_Vend_items: [] }));
        }

        // Validates that item_id has item_name, sets both, and assigns the cost.
        if (name === 'item_Id') {
            const item = viewModel?.vendors?.filter(v => v.id === Number(inve_transaction.invetra_Vend_Id))[0]?.items.find(i => i.id === Number(value));
            setItemToAdd(lastData => ({
                ...lastData,
                [name]: value,
                item_Name: item ? item.item.name : '',
                item_Cost: item ? item.cost : 0,
            }));
            return;
        }

        // Set normal inputs
        if (state === 'inve_transaction') {
            setInve_transaction((lastData) => ({
                ...lastData,
                [name]: value
            }));
        }
        else {
            setItemToAdd(lastData => ({
                ...lastData,
                [name]: value
            }));
        }
    }

    const handleAddItemToList = () => {
        if (Number(itemToAdd.item_Id) === 0) {
            messages.showErrorMessage('Favor de seleccionar un producto para agregar');
            return;
        }

        if (Number(itemToAdd.item_Quantity) === 0) {
            messages.showErrorMessage('No puede haber movimiento de producto por cantidad 0.');
            return;
        }

        const _itemtoadd = {
            item_Id: Number(itemToAdd.item_Id),
            item_Name: itemToAdd.item_Name,
            item_Cost: parseFloat(itemToAdd.item_Cost).toFixed(4),
            item_Quantity: Number(itemToAdd.item_Quantity)
        }

        const item_exists = inve_transaction.invetra_Vend_items.find(i => i.item_Id === _itemtoadd.item_Id);
        if (item_exists) {
            const item_filtered = inve_transaction.invetra_Vend_items.filter(i => i.item_Id !== _itemtoadd.item_Id);
            console.log(item_filtered);
            item_filtered.push(_itemtoadd);
            setInve_transaction(last_data => ({
                ...last_data,
                invetra_Vend_items: item_filtered
            }))
        }
        else {
            setInve_transaction(lastData => ({
                ...lastData,
                invetra_Vend_items: [...inve_transaction.invetra_Vend_items, _itemtoadd]
            }));
        }
        setItemToAdd({
            item_Cost: 0,
            item_Name: '',
            item_Id: 0,
            item_Quantity: 0
        });

    }

    const validate_data = () => {
        return (
            (inve_transaction.invetra_Type !== '') &&
            (Number(inve_transaction.invetra_Ware_Id) !== 0) &&
            (Number(inve_transaction.invetra_Inve_Id) !== 0) &&
            (Number(inve_transaction.invetra_Vend_Id) !== 0) &&
            (inve_transaction.invetra_Concept > 0) &&
            (inve_transaction.invetra_Vend_items.length > 0)
        )
    }

    const handle_save_click = async () => {
        messages.showLoadingMessage();
        if (!validate_data()) {
            messages.hideLoadingMessage();
            messages.showErrorMessage('Información incompleta. Valide que todos los campos esten completos');
            return;
        }
        const _items = inve_transaction.invetra_Vend_items.map(i => {

            return {
                id: 0,
                ItemId: i.item_Id,
                quantity: i.item_Quantity,
                cost: i.item_Cost
            }
        })

        const transaction = {
            type: inve_transaction.invetra_Type,
            date: new Date(inve_transaction.inveTran_Datetime),
            conceptId: Number(inve_transaction.invetra_Concept),
            warehouseId: Number(inve_transaction.invetra_Ware_Id),
            inventoryId: Number(inve_transaction.invetra_Inve_Id),
            vendorId: Number(inve_transaction.invetra_Vend_Id),
            // invoice: { id: inve_transaction.invetra_Invo_File ?? 0 },
            purchaseOrderId: Number(inve_transaction.purcOrd_Id),
            comments: inve_transaction.invetra_Comments,
            items: _items
        };
        const isOk = await TSave(transaction);
        if (isOk) window.location.reload();
        messages.hideLoadingMessage();
    }

    useEffect(() => {
        setViewModel(_viewModel);
    }, [_viewModel])

    return (
        <>
            {messagesHTMLElement}
            <Form className='transaction-Inventories-Form'>
                <h6 className='Containerdescripcion'>Tipo movimiento</h6>
                <hr />
                <Row>
                    <Col lg={3}>
                        <Form.Select
                            name='invetra_Type'
                            value={inve_transaction.invetra_Type}
                            onChange={(e) => { handleChange(e); }}
                        >
                            <option value={'E'}>Entrada</option>
                            <option value={'S'}>Salida</option>
                        </Form.Select>
                    </Col>
                    <Col lg={4} className='mt-3 mt-md-0'>
                        <Form.Select
                            name='invetra_Concept'
                            value={inve_transaction.invetra_Concept}
                            onChange={(e) => { handleChange(e); }}
                        >
                            <option value={0}>Selecciona concepto...</option>
                            {
                                viewModel?.concepts?.filter(c => c.type === inve_transaction.invetra_Type).map((concept) => (
                                    <option key={concept.id} value={concept.id}>{concept.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                </Row>
                <Row>
                    <Col lg={10}>
                        <Form.Control
                            type='text'
                            as={'textarea'}
                            placeholder='Comentarios'
                            name='invetra_Comments'
                            value={inve_transaction.invetra_Comments}
                            onChange={e => { handleChange(e); }}
                            className='mt-3'
                        />
                    </Col>
                </Row>
                <br />
                <h6 className='Containerdescripcion'>Información del movimiento</h6>
                <hr />
                <Row>
                    <Col lg={4}>
                        <Form.Label>Almacén</Form.Label>
                        <Form.Select
                            name='invetra_Ware_Id'
                            value={inve_transaction.invetra_Ware_Id}
                            onChange={handleChange}
                        >
                            <option value={0}>Selecciona almacén...</option>
                            {
                                viewModel?.warehouses?.map(warehouse => (
                                    <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col lg={4}>
                        <Form.Label>Inventario</Form.Label>
                        <Form.Select
                            name='invetra_Inve_Id'
                            value={inve_transaction.invetra_Inve_Id}
                            onChange={handleChange}
                        >
                            <option value={0}>Selecciona inventario...</option>
                            {
                                viewModel?.inventories?.map(inventory => (
                                    <option key={inventory.id} value={inventory.id}>{inventory.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col lg={4}>
                        <Form.Label>Proveedor</Form.Label>
                        <Form.Select
                            name='invetra_Vend_Id'
                            value={inve_transaction.invetra_Vend_Id}
                            onChange={handleChange}
                        >
                            <option value={0}>Selecciona proveedor...</option>
                            {
                                viewModel?.vendors?.map(vendor => (
                                    <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col lg={4}>
                        <Form.Label>Factura (opcional)</Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Factura'
                            defaultValue={inve_transaction.invetra_Invo_File ? inve_transaction.invetra_Invo_File.name : ''}
                            title={inve_transaction.invetra_Invo_File ? inve_transaction.invetra_Invo_File.name : ''}
                            disabled
                        />
                        <Form.Label
                            id='label-fileupload-input-invoices'
                            htmlFor='fileupload-input'
                        >
                            Cargar desde un archivo
                        </Form.Label>
                        {
                            inve_transaction.invetra_Invo_File &&
                            (
                                <img src={icono_borrar} className='mx-2' onClick={() => {
                                    setInve_transaction(last_data => ({ ...last_data, invetra_Invo_File: null }))
                                    fileRef.current.value = null;
                                }} />
                            )
                        }
                        <Form.Control
                            type="file"
                            accept=".xml"
                            id='fileupload-input'
                            onChange={HandleFileInvoiceChange}
                            ref={fileRef}
                            className='d-none'
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={4}>
                        <Form.Label>Fecha</Form.Label>
                        <Form.Control
                            type='date'
                            name='inveTran_Datetime'
                            value={inve_transaction.inveTran_Datetime}
                            onChange={e => { handleChange(e); }}
                        />
                    </Col>
                    <Col lg={4}>
                        <Form.Label>Orden de compra asociada</Form.Label>
                        <Form.Select
                            name='purcOrd_Id'
                            value={inve_transaction.purcOrd_Id}
                            onChange={e => { handleChange(e); }}
                        >
                            <option value={0}>Selecciona orden de compra...</option>
                            {
                                viewModel?.purchaseOrders?.map(op => (
                                    <option key={op.id} value={op.id}>{op.id}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                </Row>
                <h6 className='Containerdescripcion'>Productos</h6>
                <hr />
                <br />
                <Row>
                    <Col sm={12} md={6}>
                        <Form.Label>Producto</Form.Label><br />
                        <Form.Select
                            className='w-75 mx-md-5 d-inline-block'
                            name='item_Id'
                            value={itemToAdd.item_Id}
                            onChange={(e) => { handleChange(e, 'itemToAdd') }}
                        >
                            <option value={0}>Selecciona producto...</option>
                            {
                                viewModel?.vendors?.filter(v => v.id === Number(inve_transaction.invetra_Vend_Id))[0]?.items.map(item => (
                                    <option key={item.id} value={item.id} >{item.item.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col sm={12} lg={3}>
                        <Form.Label>Cantidad</Form.Label><br />
                        <Form.Control
                            className='w-75 ma-5 ma-md-0 d-inline-block text-center'
                            type='text'
                            placeholder='Cantidad'
                            name='item_Quantity'
                            value={itemToAdd.item_Quantity}
                            onChange={(e) => { handleChange(e, 'itemToAdd') }}
                        />
                    </Col>
                    <Col sm={12} md={3}>
                        <Form.Label>Costo</Form.Label><br />
                        <Form.Control
                            className='w-75 ma-5 ma-md-0 d-inline-block text-center'
                            type='text'
                            placeholder='Costo'
                            name='item_Cost'
                            value={'$' + itemToAdd.item_Cost}
                            onChange={(e) => { handleChange(e, 'itemToAdd') }}
                        />
                    </Col>
                </Row>
                <br />
                <div className='w-100 d-flex flex-row justify-content-end'>
                    <Button
                        variant='success ms-2'
                        onClick={handleAddItemToList}
                    >
                        Agregar
                    </Button>
                </div>
                <hr />
            </Form>
            <Table
                hover
                striped
                className='GenericTable items-inventory-mov-table mb-5'
            >
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Costo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        inve_transaction.invetra_Vend_items.map((item, index) => (
                            <tr key={index} className='text-center'>
                                <td>{item.item_Id}</td>
                                <td>{item.item_Name}</td>
                                <td>{item.item_Quantity}</td>
                                <td>{'$ ' + (item.item_Cost * item.item_Quantity).toFixed(2)}</td>
                                <td>
                                    <img
                                        src={icono_borrar}
                                        onClick={() => { Delete_item_selected(item); }}
                                    />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <Row className='d-flex flex-row justify-content-center mx-5'>
                <Button
                    variant='success w-50'
                    onClick={() => {
                        messages.showConfirmMessage(() => { return () => { handle_save_click(); } });
                    }}
                >
                    Guardar
                </Button>
            </Row>
        </>
    )
}

const DataTableTransaction = ({ _viewModel }) => {
    const messages = UseMessages();
    const messagesHTMLElement = messages.messagesHTMLElement;
    const [viewModel, setViewModel] = useState(null);
    useEffect(() => {
        setViewModel(_viewModel);
    }, [_viewModel])

    return (
        <Row>
            {messagesHTMLElement}
            <Table
                hover
                className='GenericTable overedflow'
            >
                <thead>
                    <tr>
                        <th>Folio</th>
                        <th>Tipo</th>
                        <th>Fecha</th>
                        <th>Almacen</th>
                        <th>Inventario</th>
                        <th>Proveedor</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        _viewModel?.transactions?.map((inveTransaction, index) => (
                            <tr key={index}>
                                <td className='text-center'>{inveTransaction.id}</td>
                                <td className='text-center'>{inveTransaction.type === 'E' ? 'Entrada' : 'Salida'}</td>
                                <td className='text-center'>{new Date(inveTransaction.date).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                <td className='text-center'>{inveTransaction.warehouse.name}</td>
                                <td className='text-center'>{inveTransaction.inventory.name}</td>
                                <td className='text-center'>{inveTransaction.vendor.name}</td>

                            </tr>
                        ))
                    }
                </tbody>
            </Table >
        </Row>
    )
}


export default Transaction;