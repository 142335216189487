import React, { useEffect, useState } from 'react';
import { Form, Button, Table, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Layout from '../../../components/General/layout';
import Cookies from 'js-cookie';
import { UrlApi } from '../../../Global/Globals';
import { icono_cancelar, icono_editar, icono_buscar } from '../../../Assets/Images/images';
import './CatalogoUsuarios.css';
import UseMessages from 'components/Mensajes/messages_section';
import { ViewModel as Employee_viewModel, Save, getMenuPermissions } from 'Data/Employees/employeesData';

const CatalogoUsuarios = () => {
  const messages = UseMessages();
  const htmlElementMessages = messages.messagesHTMLElement;
  const [viewModel, setViewModel] = useState(null);
  const [isformVisible, setFormVisible] = useState(true);
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    username: '',
    IdUser: undefined,
    email: '',
    password: '',
    menuSelections: [],
  });


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }

  const handleNewClick = () => {
    setFormVisible(!isformVisible);
    setFormData({
      firstname: '',
      lastname: '',
      username: '',
      IdUser: undefined,
      email: '',
      password: '',
      menuSelections: [],
    });
  }

  const handleMenuCheckboxChange = (isChecked, idMenu) => {
    // Esto evita que duplique en el array los menus
    if (formData.menuSelections.includes(idMenu) && isChecked) return;
    if (isChecked) {
      setFormData((prevData) => ({
        ...prevData,
        menuSelections: [...prevData.menuSelections, idMenu],
      }));
    }
    else {
      setFormData((prevData) => ({
        ...prevData,
        menuSelections: prevData.menuSelections.filter((menuId) => menuId !== idMenu),
      }));
    }
  };

  const handleEditUserClick = (employee) => {
    messages.showLoadingMessage();

    const { id, firstName, lastName, email, user } = employee;

    const employee_menus = viewModel.jobCodes.filter(jc => jc.employee.id === id);
    const menus = [];
    employee_menus.forEach(n => {
      menus.push(n.systemPermission.id);
    });

    console.log(employee_menus);

    setFormData({
      ...formData,
      firstname: firstName,
      lastname: lastName,
      menuSelections: menus,
      email: email,
      IdUser: id,
      username: user
    });

    setFormVisible(true);
    messages.hideLoadingMessage();
  }

  const handleSaveClick = async () => {
    messages.showLoadingMessage();

    if (formData.username === '' || formData.email === '') {
      messages.hideLoadingMessage();
      messages.showErrorMessage('Debe llenar todos los campos antes de enviar.');
      return;
    }

    const employee = {
      id: Number(formData.IdUser ?? null),
      firstName: formData.firstname,
      lastName: formData.lastname,
      email: formData.email,
      user: formData.username,
      password: formData.password
    };

    const permissions = [];

    formData.menuSelections.forEach(element => {
      permissions.push({ id: 0, employee: { id: employee.id }, systemPermission: { id: element } });
    });
    console.log(JSON.stringify(employee));
    console.log(JSON.stringify(permissions));
    const isOk = await Save(employee, permissions);
    if (isOk) window.location.reload();
    messages.hideLoadingMessage();
  }

  const handleDeleteClick = (Empl_Id) => {
    messages.showConfirmMessage();

    const token = Cookies.get('token');
    const Employee = {
      empl_token: token,
      empl_id: Empl_Id
    }
    const url = UrlApi + 'employee/DeleteUser';
    console.log(Employee);
    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(Employee)
    })
      .then((data) => { window.location.reload(); })
      .catch(error => {
        console.error(error);
        messages.showErrorMessage('Ocurrio un error durante el proceso. Contacta al administrador del sitio')
      });
  }

  useEffect(async () => {
    messages.showLoadingMessage();

    const _viewModel = await Employee_viewModel();
    if (_viewModel !== null) setViewModel(_viewModel);
    console.log(_viewModel);
    messages.hideLoadingMessage();
  }, [])


  return (
    <Layout>
      <>
        {htmlElementMessages}
        <h4>Usuarios</h4>
        <p className='Containerdescripcion'>
          Catalogo para el alta, baja y modificación de los usuarios en sistema
        </p>
        <hr />
        <Button variant={isformVisible ? 'secondary' : 'primary'} onClick={handleNewClick}>
          {isformVisible ? "Ir a Usuarios" : "Nuevo"}
        </Button>
        <hr />
        <br />
        {/* Region: Formulario de creacion */}
        {isformVisible && (
          <>
            <Form className='UserForm'>
              <p className='Containerdescripcion'><strong>Informacion General</strong></p>
              <hr />
              <Form.Group className='FormGroup-Section1'>
                <Form.Control
                  type='text'
                  id='IdUser'
                  name='IdUser'
                  placeholder='Id  de usuario'
                  value={formData.IdUser}
                  onChange={handleInputChange}
                />
                <br />
              </Form.Group>
              <Form.Group className='FormGroup-Section2'>
                <Form.Control
                  type="text"
                  id='firstname'
                  name="firstname"
                  placeholder='Nombre'
                  value={formData.firstname}
                  onChange={handleInputChange}
                />
                <br />
                <Form.Control
                  type="text"
                  id='lastname'
                  name="lastname"
                  placeholder='Apellido'
                  value={formData.lastname}
                  onChange={handleInputChange}
                />
                <br />
              </Form.Group>
              <Form.Control
                type="email"
                id='email'
                name="email"
                placeholder='Correo electronico'
                value={formData.email}
                onChange={handleInputChange}
                style={{ width: '99%' }}
              />
              <br />
              <p className='Containerdescripcion'><strong>Credenciales de Acceso</strong></p>
              <hr />
              <Form.Group className='FormGroup-Section2'>
                <Form.Control
                  type="text"
                  id='username'
                  name="username"
                  placeholder='Usuario de ingreso'
                  value={formData.username}
                  onChange={handleInputChange}
                />
                <br />
                <Form.Control
                  type="password"
                  id='password'
                  name="password"
                  placeholder='Contraseña'
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <br />
              </Form.Group>
              <br />
              <Form.Group className='FormGroup-Section3'>
                <p className='Containerdescripcion'><strong>Secciones del Menu</strong></p>
                <hr />
                <ul>
                  {
                    viewModel !== null && viewModel.layoutViews.map((menu, Index) => (
                      <li key={Index} className='menuoptionslist'>
                        <Form.Check
                          type='checkbox'
                          id={menu.parent.id + 'check'}
                          checked={formData.menuSelections.includes(menu.parent.id)}
                          value={formData.menuSelections.includes(menu.parent.id)}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            menu.children.map((submenu) => { handleMenuCheckboxChange(isChecked, submenu.id) });
                            handleMenuCheckboxChange(isChecked, menu.parent.id);
                          }}
                        />
                        &nbsp;&nbsp;
                        <Form.Label htmlFor={menu.parent.id + 'check'}>{menu.parent.text}</Form.Label>
                        <ul>
                          {
                            menu.children.map((submenu, Index_1) => (
                              <li key={Index_1} className='menuoptionslist'>
                                <Form.Check
                                  type='checkbox'
                                  id={submenu.id + 'check'}
                                  checked={formData.menuSelections.includes(submenu.id)}
                                  onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    handleMenuCheckboxChange(true, menu.parent.id);
                                    handleMenuCheckboxChange(isChecked, submenu.id);
                                  }}
                                />
                                &nbsp;&nbsp;
                                <Form.Label htmlFor={submenu.id + 'check'}>
                                  {submenu.text}
                                </Form.Label>
                              </li>
                            ))
                          }
                        </ul>
                      </li>
                    ))
                  }
                </ul>
              </Form.Group>
              <br />

              <Button variant="primary" onClick={() => {
                messages.showConfirmMessage(() => { return () => { handleSaveClick(); } })
              }}>
                Guardar
              </Button>
              <br />
              <hr />
              <br />
            </Form>
          </>
        )}
        {/* Region: lista de usuarios */}
        {!isformVisible && (
          <>
            <Form.Control
              type='text'
              placeholder='Buscar...'
              className='searchUserGrid'
            />
            <img className='btn-Search' src={icono_buscar} width={25} height={25} alt='botton search' />
            <br />
            <br />
            <Row>
              <Table hover bordered={true} className='GenericTable overedflow text-center'>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Activo</th>
                    <th>Bloqueado</th>
                    <th>Nombre</th>
                    <th>Usuario</th>
                    <th>email</th>
                    <th>Opciones</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    viewModel !== null && viewModel.employees.map((employee) => (
                      <tr key={employee.id}>
                        <td>{employee.id}</td>
                        <td><Form.Check defaultChecked={employee.active} disabled /></td>
                        <td><Form.Check defaultChecked={employee.locked} disabled /></td>
                        <td>{employee.firstName + ' ' + employee.lastName}</td>
                        <td>{employee.user}</td>
                        <td>{employee.email}</td>
                        <td>
                          <Link onClick={() => {
                            handleEditUserClick(employee);
                          }}>
                            <img src={icono_editar} alt='edit' />
                          </Link> | &nbsp;
                          <Link onClick={() => {
                            console.log(employee.id);
                            messages.showLoadingMessage();
                            messages.showConfirmMessage(() => { return () => { handleDeleteClick(employee.id); } });
                          }}  >
                            <img src={icono_cancelar} alt='delete' />
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Row>
          </>
        )}
      </>
    </Layout>
  );
}

export default CatalogoUsuarios;
