
import UseMessages from 'components/Mensajes/messages_section';
import Layout from 'components/General/layout';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { AddItemToClient, AssociateItemsViewModel, RemoveItemToClient } from 'Data/Clients/clients';
import { icono_borrar } from 'Assets/Images/images';
import { useNavigate } from 'react-router-dom';

const Associateitems = () => {
    const messages = UseMessages();
    const messagesHTMLElement = messages.messagesHTMLElement;
    const navigate = useNavigate();
    const [viewModel, setViewModel] = useState(null);
    const [itemData, setItemData] = useState({
        id: 0,
        itemId: 0,
        price: 0,
        clientId: 0
    });

    const handleOnChange = (e) => {
        const { value, name } = e.target;

        if (name === 'clientId') setItemData(prevData => ({ ...prevData, itemId: 0, price: 0, id: 0 }))

        setItemData(prevData => ({ ...prevData, [name]: value }));
    }

    const handleOnClickSave = () => {
        messages.showConfirmMessage(() => {
            return async () => {
                messages.showLoadingMessage();
                console.log(itemData);
                const isok = await AddItemToClient(itemData);
                if (isok) loadViewModel();
                messages.hideLoadingMessage();
            }
        })
    }

    const handleOnClickDelete = (item) => {
        messages.showConfirmMessage(() => {
            return async () => {
                messages.showLoadingMessage();
                const isok = await RemoveItemToClient(item.id);
                if (isok) loadViewModel();
                messages.hideLoadingMessage();
            }
        })
    }

    const handleOnClickEdit = (item) => {
        setItemData({
            id: item.id,
            clientId: item.clientId,
            itemId: item.item.id,
            price: item.price
        });
    }

    const loadViewModel = async () => {
        messages.showLoadingMessage();
        const _viewModel = await AssociateItemsViewModel();
        setViewModel(_viewModel);
        console.log(_viewModel);
        messages.hideLoadingMessage();
    }

    useEffect(() => {
        loadViewModel();
    }, [])

    return (
        <>
            {messagesHTMLElement}
            <Layout>

                <h4>Asociar producto a cliente</h4>
                <p className='text-gray-400'>
                    Modulo para la gestión de productos habiles por cliente y sus sucursales.
                </p>
                <hr />

                <Form.Select
                    className='text-center'
                    value={itemData.clientId}
                    name='clientId'
                    onChange={handleOnChange}
                >
                    <option value={0}>Selecciona Cliente...</option>
                    {
                        viewModel?.clients?.map(client => (
                            <option key={client.id} value={client.id}>{client.name}</option>
                        ))
                    }
                </Form.Select>
                <Button
                    variant='secondary mt-2'
                    onClick={() => { if (Number(itemData.clientId) !== 0) navigate(`/associateitems/${itemData.clientId}/branches`); }}
                >
                    Producto por sucursal
                </Button>

                <Row className='mt-3'>
                    <Col xl={3}>
                        <Form>
                            <Row>
                                <Col sm={12}>
                                    <Form.Label>Producto</Form.Label>
                                    <Form.Select
                                        className='text-center'
                                        value={itemData.itemId}
                                        name='itemId'
                                        onChange={handleOnChange}
                                    >
                                        <option value={0}>Seleccionar...</option>
                                        {
                                            viewModel?.items?.map(items => (
                                                <option key={items.id} value={items.id}>{items.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Col>
                                <Col sm={12} className='mt-3'>
                                    <Form.Label>Precio</Form.Label>
                                    <Form.Control
                                        className='text-center'
                                        type='number'
                                        placeholder='Precio'
                                        value={itemData.price}
                                        name='price'
                                        onChange={handleOnChange}
                                    />
                                </Col>
                                <Col sm={12} className='mt-3'>
                                    <Button
                                        variant='success'
                                        onClick={handleOnClickSave}
                                    >
                                        Guardar
                                    </Button>
                                    <Button
                                        variant='secondary ml-3'
                                        onClick={() => {
                                            setItemData(prevData => ({
                                                ...prevData,
                                                id: 0,
                                                itemId: 0,
                                                price: 0,
                                            }))
                                        }}
                                    >
                                        Nuevo
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col className='border-l border-l-1 border-l-zinc-500 mt-3 mt-xl-0'>
                        <Table className='GenericTable'>
                            <thead>
                                <tr>
                                    <th>id</th>
                                    <th>Producto</th>
                                    <th>Costo</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                {
                                    viewModel?.clients?.find(c => c.id == itemData.clientId)?.items.map(item => (
                                        <tr key={item.id} onClick={() => { handleOnClickEdit(item); }}>
                                            <td>{item.item.id}</td>
                                            <td>{item.item.name}</td>
                                            <td>$ {item.price}</td>
                                            <td>
                                                <img src={icono_borrar}
                                                    onClick={() => { handleOnClickDelete(item); }}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Layout>
        </>
    );
}


export default Associateitems;