import React, { useEffect, useState } from 'react';
import Layout from '../../../components/General/layout';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import { UrlApi, userHasPermission } from '../../../Global/Globals';
import Cookies from 'js-cookie';
import ErMessage from '../../../components/Mensajes/ErrorMessage';
import ConfirmMessage from '../../../components/Mensajes/ConfirmMessage';

import './cuts.css';

//Imagenes
import { icono_cancelar, icono_editar, icono_buscar } from '../../../Assets/Images/images';
import { useNavigate } from 'react-router-dom';


const Cuts = () => {

    const [showLoadingMessage, setShowLoadingMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState(false);
    const [showConfirmMessage, setshowConfirmMessage] = useState(false);
    const [funcToExecute, setfuncToExecute] = useState({ callback: () => { } });
    const [listCuts, setListCuts] = useState([]);
    const [cutData, setCutData] = useState({ cut_Id: null, cut_Name: '' });
    const navigate = useNavigate();


    const handleErrorMessageCloseClick = () => {
        setShowErrorMessage(!showErrorMessage);
    }

    const handleOnCloseConfirmMessage = () => {
        setshowConfirmMessage(false);
    }

    const handleinputCutChange = (e) => {
        const { name, value } = e.target;

        setCutData(prevdata => ({
            ...prevdata,
            [name]: value,
        }));
    }

    const handleNewClick = () => {
        setCutData({ cut_Id: null, cut_Name: '' })
    }

    const handleEditClick = (cut) => {
        setCutData({
            cut_Id: cut.cut_Id,
            cut_Name: cut.cut_Name
        })
    }

    const handleDeleteClick = (cut) => {
        const token = Cookies.get('token');
        const url = UrlApi + 'items/deletecut?Empl_Token=' + token;

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ cut_Id: cut.cut_Id, cut_Name: cut.cut_Name })
        }).then(data => { if (data.ok) { window.location.reload(); } })
            .catch(error => {
                setErrorMessage('Error en servidor');
                setShowErrorMessage("")
            })
    }

    const handleSaveClick = () => {
        if ((cutData.cut_Id === null || cutData.cut_Id === '') && cutData.cut_Name === '') {
            setErrorMessage('Debe completar todos los campos necesarios antes');
            setShowErrorMessage(true);
            return;
        }

        setShowLoadingMessage(true);

        const token = Cookies.get('token');
        const url = UrlApi + 'items/savecut?Empl_Token=' + token;
        const parameters = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                cut_Id: (cutData.cut_Id === '' ? 0 : cutData.cut_Id) ?? 0,
                cut_Name: cutData.cut_Name
            })
        };

        fetch(url, parameters)
            .then(() => { window.location.reload(); })
            .catch(error => {
                setShowLoadingMessage(false);
                setErrorMessage("Error durante el proceso");
                setShowErrorMessage(true);
            })
    }

    useEffect(() => {
        document.title = 'SIFFS - Cortes';
        
        const token = Cookies.get('token');
        if (!userHasPermission({ sysSection: 10, Token: token }))
            navigate('/', { replace: true });
        
        const url = UrlApi + 'items/getcuts?Empl_Token=' + token;
        setShowLoadingMessage(true);

        fetch(url, { method: 'GET' })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data) { setListCuts(data); }
            })
            .catch(error => {
                setShowLoadingMessage(false);
                setErrorMessage('Error interno, contacte a administrador del sitio');
                setShowErrorMessage(true);
            });

        setShowLoadingMessage(false);
    }, [])

    return (
        <Layout>
            {
                showErrorMessage && (<ErMessage Message={ErrorMessage} onCloseClick={handleErrorMessageCloseClick} />)
            }
            {
                showConfirmMessage && (<ConfirmMessage Close={handleOnCloseConfirmMessage} onConfirm={funcToExecute.callback} />)
            }
            <>
                <h4> Cortes </h4>
                <p className='Containerdescripcion'>
                    Modulo para gestion de tipo de cortes de los producto base
                </p>
                <hr />
                <Row>
                    <Col lg={3}>
                        <Form className='formCuts'>
                            <Form.Label>ID</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Id del corte'
                                name='cut_Id'
                                value={cutData.cut_Id ?? ''}
                                onChange={(e) => { handleinputCutChange(e); }}
                            />
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Nombre del corte'
                                name='cut_Name'
                                value={cutData.cut_Name}
                                onChange={(e) => { handleinputCutChange(e); }}
                            />
                            <br />
                            <Button
                                variant='success'
                                onClick={() => {
                                    setfuncToExecute({ callback: () => { handleSaveClick(); } })
                                    setshowConfirmMessage(true);
                                }}
                            >
                                Guardar
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                variant='secondary'
                                onClick={handleNewClick}
                            >
                                Nuevo
                            </Button>
                        </Form>
                    </Col>
                    <Col className='GridCutsContainer'>
                        <Form.Control type='text' placeholder='Buscar' className='SearchVegetable' />
                        <img
                            className='btn-Search'
                            src={icono_buscar}
                            width={25}
                            height={25}
                            alt='botton search'
                        />
                        <Table
                            className='GenericTable text-center'
                            hover={true}
                            striped={true}
                        >
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Corte</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listCuts.map((cut, index) => (
                                        <tr key={index} onClick={() => { handleEditClick(cut); }} >
                                            <td>{cut.cut_Id}</td>
                                            <td>{cut.cut_Name}</td>
                                            <td>
                                                <span>
                                                    <img
                                                        src={icono_editar}
                                                        alt='icon_edit'
                                                        onClick={() => { handleEditClick(cut); }}
                                                    />
                                                    &nbsp;|&nbsp;
                                                    <img
                                                        src={icono_cancelar}
                                                        alt=' icon_delete'
                                                        onClick={() => {
                                                            setfuncToExecute({ callback: () => { handleDeleteClick(cut); } })
                                                            setshowConfirmMessage(true);
                                                        }}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default Cuts;