import React, { useEffect, useState } from 'react';
import { Col, Container, Navbar, Row, Button } from 'react-bootstrap';
import ConfirmMessage from 'components/Mensajes/ConfirmMessage';
import ErrorMessage from 'components/Mensajes/ErrorMessage';
import Loading from 'components/Mensajes/loading';
import Freshco_Logo from '../../../Assets/Images/freshco-blanco.png';
import './invoicesReception.css';
import { UrlApi } from 'Global/Globals';

const InvoicesReception = () => {
    const [messageErrorText, setMessageErrorText] = useState('');
    const [isMessageErrorShow, setIsMessageErrorShow] = useState(false);
    const [functionToExecute, setFunctionToExecute] = useState({ callback: () => { } });
    const [isMessageConfirmShow, setIsMessageConfirmShow] = useState(false);
    const [isMessageLoadingShow, setIsMessageLoadingShow] = useState(false);
    const [pdfInvoices, setPDFInvoice] = useState(null);
    const [invoNumber, setInvoNumber] = useState(0);

    const getPDFInvoice = () => {
        const urlActual = window.location.href.toLowerCase();
        const params = new URLSearchParams(urlActual);

        const invo_Id = params.get("invo_id");
        const clie_Token = params.get("clie_token");

        if (!(invo_Id || clie_Token)) {
            setMessageErrorText("No se encontro informacion con los parametros enviados");
            setIsMessageErrorShow(true);
            return;
        }

        const url = `${UrlApi}api/invoices/external/GetInvoiceClient?Clie_Token=${clie_Token ?? 0}&Invo_Id=${invo_Id ?? 0}`;
        fetch(url).then(response => response.blob())
            .then((data) => {
                setPDFInvoice(data);
                setInvoNumber(invo_Id);
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        document.title = 'Freshco - factura Cliente';
        getPDFInvoice();
    }, [])

    return (
        <>
            <Navbar bg="dark" variant='dark' expand="lg">
                <Navbar.Brand href="https://freshcomexico.com">
                    <img src={Freshco_Logo} alt='freshco_logo' />
                </Navbar.Brand>
            </Navbar>
            {
                isMessageConfirmShow && (<ConfirmMessage Close={() => { setIsMessageConfirmShow(false); }} onConfirm={functionToExecute.callback} />)
            }
            {
                isMessageErrorShow && (<ErrorMessage Message={messageErrorText} onCloseClick={() => { setIsMessageErrorShow(false); }} />)
            }
            {
                isMessageLoadingShow && (<Loading />)
            }
            <Container className='infoContainers'>
                <Row>
                    <Col lg={9} className='container-content-invoices'>
                        <iframe src={pdfInvoices ? URL.createObjectURL(pdfInvoices) : ''} className='iframe-content-invoices'></iframe>
                    </Col>
                    <Col lg={3}>
                        <h4>Factura - {invoNumber}</h4>
                        <p className='Containerdescripcion'>
                            Si encuentra algún problema con la entrega de su factura, estamos aquí para ayudarle.
                            Por favor, presione el botón "Reportar un problema con mi entrega" a continuación si
                            necesita asistencia inmediata. También puede ponerse en contacto con nuestro servicio
                            de atención al cliente llamando al siguiente número: [Número de contacto].
                        </p>

                        <hr />
                        <Button variant='success w-100 mt-2'>
                            Confirmar
                        </Button>
                        <Button variant='primary w-100 mt-2'>
                            Reportar un problema con mi entrega
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default InvoicesReception;