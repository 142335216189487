import React from 'react';

import gif_loading from '../../Assets/Images/loading.gif';
function Loading() {

    const style = {
        height: (window.innerHeight > document.body.scrollHeight) ? window.innerHeight : document.body.scrollHeight,
        width: ((window.innerWidth > document.body.scrollWidth) ? window.innerWidth : document.body.scrollWidth),
        zIndex: 2
    }

    return (
        <>
            <div className='waitFormContainerFull fadeIn' style={style}>
                <div className={`waitForm`}>
                    <br />
                    <img src={gif_loading} width={30} height={30} alt='loadingGif' />
                    <center>Espere, estamos procesando su solicitud</center>
                    <br />
                </div>
            </div>
        </>
    );
}

export default Loading;