import React, { useEffect, useState } from 'react';
import './purchase.css';
import Layout from 'components/General/layout';
import { useNavigate } from "react-router-dom";
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import UseMessages from 'components/Mensajes/messages_section';
import { icono_borrar } from 'Assets/Images/images';
import { Save, ViewModel } from 'Data/Purchase/purchaseData';


const Purchase = () => {
    const [viewModel, setViewModel] = useState(null);
    const [date_order, setDateOrder] = useState(new Date().toLocaleDateString('en-CA'));
    const [item_selected, setItemSelected] = useState(0);
    const [item_selected_quantity, setItemSelectedQuantity] = useState(0);
    const [order, setOrder] = useState({
        warehouseId: 0,
        inventoryId: 0,
        CurrencyId: 1,
        date: new Date().toLocaleDateString('en-CA'),
        vendorId: 0,
        order_items: []
    })
    const messages = UseMessages();
    const MessagesHTMLElement = messages.messagesHTMLElement;
    const navigate = useNavigate();

    document.title = 'SIFFS - Ordenes de compra';

    const handleOnChange = (event) => {
        const { name, value } = event.target;

        if (name === 'CurrencyId') setDateOrder(prevData => ({ ...prevData, order_items: [] }));

        setOrder(prevData => ({ ...prevData, [name]: value }));
    }

    const addItemToList = () => {
        if (item_selected <= 0 || item_selected_quantity <= 0) {
            messages.showErrorMessage('Debe elegir un producto y asinarle cantidad para agregar');
            return;
        }

        const item_exists = order.order_items.find(i => i.item.id === item_selected);
        if (item_exists) {
            const mod_array_item_list = order.order_items.slice();
            mod_array_item_list.forEach(i => {
                if (i.item.id === item_selected) i.quantity = item_selected_quantity;
            })
            setOrder(prevData => ({ ...prevData, order_items: mod_array_item_list }));
        }
        else {
            const new_array_item_list = order.order_items.slice();
            let item = viewModel.vendors.find(v => v.id === Number(order.vendorId)).items.find(i => i.item.id === item_selected);
            console.log(item);
            item.quantity = item_selected_quantity;

            new_array_item_list.push(item);
            setOrder(prevData => ({ ...prevData, order_items: new_array_item_list }))
        }
        setItemSelected(0);
        setItemSelectedQuantity(0);
    }

    const deleteItemFromlist = (item) => {
        const item_list_filtered = order.order_items.filter(i => i.id !== item.id);
        setOrder(prev_data => ({ ...prev_data, order_items: item_list_filtered }));
    }

    const savePurchaseOrder = async () => {
        messages.showLoadingMessage();
        const PurchaseOrder = {
            warehouseId: Number(order.warehouseId),
            inventoryId: Number(order.vendorId),
            vendorId: Number(order.vendorId),
            currencyId: Number(order.CurrencyId),
            estimateArrive: order.date,
            items: order.order_items.map(item => {
                return {
                    id: 0,
                    PurchaseOrderId: 0,
                    Quantity: Number(item.quantity),
                    cost: Number(item.cost),
                    itemId: Number(item.item.id),
                }
            })

        }

        const isOk = await Save(PurchaseOrder);
        if (isOk) window.location.reload();
        messages.hideLoadingMessage();
    }

    useEffect(async () => {
        messages.showLoadingMessage();
        const _viewModel = await ViewModel();
        setViewModel(_viewModel);
        messages.hideLoadingMessage();
    }, [])

    return (
        <Layout>
            {MessagesHTMLElement}

            <h4>Ordenes de compra</h4>
            <p className='Containerdescripcion'>
                Modulo para la gestión de ordenes de compras
            </p>

            <hr />

            <Button variant='secondary' onClick={() => { navigate('/purchases/list'); }}>Lista de OC</Button>
            <Button variant='primary ml-3'>Nuevo</Button>
            <Button variant='success ml-3' onClick={() => {
                messages.showConfirmMessage(() => { return () => { savePurchaseOrder(); } })
            }}>
                Guardar
            </Button>

            <hr />

            <Form>
                <h5 className='Containerdescripcion'>General</h5>
                <Row className='w-100 flex justify-center'>
                    <Col xs={12} md={3}>
                        <Form.Label>Proveedor</Form.Label>
                        <Form.Select
                            name='vendorId'
                            value={order.vendorId}
                            onChange={handleOnChange}
                        >
                            <option value={0}>Selecciona proveedor...</option>
                            {
                                viewModel?.vendors?.map(vendor => (
                                    <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>

                    <Col xs={12} md={3}>
                        <Form.Label>Almacén</Form.Label>
                        <Form.Select
                            name='warehouseId'
                            value={order.warehouseId}
                            onChange={handleOnChange}
                        >
                            <option>Selecciona Almacén...</option>
                            {
                                viewModel?.warehouses?.map(warehouse => (
                                    <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>

                    <Col xs={12} md={3}>
                        <Form.Label>Inventario</Form.Label>
                        <Form.Select>
                            <option>Selecciona Inventario...</option>
                            {
                                viewModel?.inventories?.map(inventory => (
                                    <option key={inventory.id} value={inventory.id}>{inventory.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col xs={12} md={3}>
                        <Form.Label>Fecha de entrega</Form.Label>
                        <Form.Control
                            className='ml-0 ml-md-5 mt-3 mt-md-0'
                            type='date'
                            name='date'
                            value={order.date}
                            onChange={handleOnChange}
                        />
                    </Col>
                </Row>

                <hr />

                <h5 className='Containerdescripcion'>Detalles</h5>
                <p className='Containerdescripcion'>Al cambiar de divisa, se borraran todos los productos seleccionados</p>
                <Row className='flex justify-center mt-3'>
                    <Col xs={12} md={4}>
                        <Form.Select
                            name='CurrencyId'
                            value={order.CurrencyId}
                            onChange={handleOnChange}
                        >
                            <option value={0}>Selecciona divisa...</option>
                            {
                                viewModel?.currencies?.map(currency => (
                                    <option key={currency.id} value={currency.id}>{currency.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col xs={12} md={4}>
                        <Form.Select
                            className=''
                            name='item_selected'
                            value={item_selected}
                            onChange={(e) => { setItemSelected(Number(e.target.value)); }}
                        >
                            <option value={0}>Selecciona producto...</option>
                            {
                                viewModel?.vendors?.find(v => v.id == order.vendorId)?.items?.map(item => {
                                    if (item.currency.id === 1) {
                                        return (<option key={item.item.id} value={item.item.id}>{item.item.name}</option>)
                                    }
                                })
                            }
                        </Form.Select>
                    </Col>

                    <Col xs={12} md={2}>
                        <Form.Control
                            className='ml-0 ml-md-5 mt-2 mt-md-0 text-center'
                            type='text'
                            placeholder='Cantidad'
                            name='item_selected_quantity'
                            value={item_selected_quantity}
                            onChange={e => { const isValid = /^\d+$/.test(e.target.value); if (isValid) setItemSelectedQuantity(e.target.value); }}
                        />
                    </Col>

                    <Col xs={12} md={2}>
                        <Button
                            variant='success'
                            className='w-100 mt-2 mt-md-0 ml-0 ml-md-5'
                            onClick={addItemToList}
                        >
                            Agregar
                        </Button>
                    </Col>
                </Row>

                <Row className='p-0 p-md-3'>
                    <h5 className='mt-5'>Total: $ <span className='text-info'>{order.order_items.reduce((acumulador, item) => acumulador + (item.cost * item.quantity), 0).toFixed(2)}</span></h5>
                    <Table className='GenericTable overedflow mt-3'>
                        <thead>
                            <tr>
                                <th>Clave</th>
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Costo</th>
                                <th>Importe</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                order.order_items && order.order_items.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.item.id}</td>
                                        <td>{item.item.name}</td>
                                        <td>{item.quantity}</td>
                                        <td>{'$ ' + item.cost}</td>
                                        <td>{'$ ' + item.cost * item.quantity}</td>
                                        <td>
                                            <img src={icono_borrar} onClick={() => { deleteItemFromlist(item); }} />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Row>
            </Form>

            <hr />
        </Layout>
    );
}

export default Purchase;