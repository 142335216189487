import { api_endpoints } from "Global/Globals";
import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get('token');

export async function validateToken() {
    if (token) {
        try {
            const response = await axios.get(`${api_endpoints.validate_token}/${token}`);
            return response.status === 200;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    return false;
}

export async function login(username, password) {
    try {
        const response = await axios.post(api_endpoints.login, { user: username, password: password }, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        Cookies.set('token', response.data.token);
        Cookies.set('UserInfo', JSON.stringify(response.data));
        window.location.href = "/home";
        return 'ok';
    } catch (error) {
        console.error(error);
        debugger
        if (error.response && error.response.status === 423) {
            return 'locked';
        } else {
            return 'error';
        }
    }
}

export async function getMenuPermissions() {
    try {
        const { data } = await axios.get(`${api_endpoints.get_permissions}/${token}`);
        return data;
    } catch (error) {
        console.log(error.message);
        return null;
    }
}

export async function logout() {
    axios.get(`${api_endpoints.logout}/${token}`);
}

export async function getImageProfile() {
    try {
        const { data } = await axios.get(`${api_endpoints.get_profile_image}/${token}`, { responseType: 'blob' });
        return data;
    } catch (error) {
        console.log(error.message);
        return null;
    }

}

export async function setimageprofile(image_file) {
    if (!image_file) throw new Error("image_file field is required.");
    const form_data = new FormData();
    console.log(image_file);
    form_data.append("image", image_file);

    try {
        await axios.post(`${api_endpoints.set_profile_image}/${token}`, form_data, { headers: { 'Content-Type': 'multipart/form-data' } });
        return true;
    } catch (error) {
        console.error(error.message);
        return false;
    }
}

export async function getTheme() {
    let is_dark = false;
    try {
        const { data } = await axios.get(`${api_endpoints.get_theme}/${token}`);
        return data ? 'dark' : 'white';
    } catch (error) {
        console.log(error.message);
        return null;
    }

    return is_dark;
}

export async function setTheme(isDarkTheme) {
    if (isDarkTheme === undefined || isDarkTheme === null) throw new Error("parameter isDarkTheme is required.");
    let isOk = false;
    await axios.post(`${api_endpoints.set_theme}/${token}/${isDarkTheme}`)
        .then(response => { isOk = response.status === 200 })
        .catch(error => { console.error(error); isOk = false; });

    return isOk;
}

export async function ViewModel() {
    try {
        const { data } = await axios.get(api_endpoints.get_employees_viewmodel, { headers: { 'Authorization': token } });
        return data;
    } catch (error) {
        console.error(error.message);
        return null;
    }
}

export async function Save(employee, permissions) {
    if (employee === undefined || permissions === undefined) throw new Error("paramters employee and permissions is required.");
    try {
        const { employee_id } = await axios.post(api_endpoints.save_employees, employee, { headers: { 'Authorization': token, 'Content-Type': 'application/json' } });
        employee.id = employee_id;
        await axios.post(api_endpoints.save_employees_permissions, permissions, { headers: { 'Authorization': token, 'Content-Type': 'application/json' } })
        return true;
    } catch (error) {
        console.error(error.message);
        return false;
    }
}