import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';

import './styles/Message.css';
import { useFetcher } from 'react-router-dom';

function ConfirmMessage({ Close, onConfirm }) {

    const btnRef = useRef(null);

    const style = {
        height: (window.innerHeight > document.body.scrollHeight) ? window.innerHeight : document.body.scrollHeight,
        width: ((window.innerWidth > document.body.scrollWidth) ? window.innerWidth : document.body.scrollWidth),
        zIndex: 2
    }

    useEffect(() => {
       btnRef.current.focus(); 
    },[])

    return (
        <>
            <div className='waitFormContainerFull fadeIn' style={style}>
                <div className={`waitForm`}>
                    <br />
                    <h4 className='text-warning' style={{textShadow: '0px 0px 3px black' }}>Precaución</h4>
                    <center>¿Esta seguro de realizar la siguiente accion?</center>
                    <br />
                    <div>
                        <Button
                            variant='success'
                            className='btnConfirmMessage'
                            onClick={() => { Close(); onConfirm(); }}
                            ref={btnRef}
                        >
                            Aceptar
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                            variant='light'
                            className='btnConfirmMessage'
                            onClick={() => { Close(); }}
                        >
                            Cancelar
                        </Button>
                    </div>
                    <br />
                </div>
            </div>
        </>
    );
}


export default ConfirmMessage;