import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import Layout from 'components/General/layout';
import './vendors.css';
import { icono_cancelar, icono_editar, icono_buscar } from "../../../Assets/Images/images";
import { useNavigate } from 'react-router-dom';
import UseMessages from 'components/Mensajes/messages_section';
import { Delete, Save, ViewModel } from 'Data/Vendors/vendorData';

const Vendors = () => {
    const messages = UseMessages();
    const htmlElementMessages = messages.messagesHTMLElement;
    const [viewModel, setViewModel] = useState(null);
    const [vendorData, setVendorData] = useState({
        vend_Id: 0,
        vend_TradeName: '',
        vend_LegalName: '',
        vend_Address: '',
        vend_Enable: false,
        vend_RFC: '',
        vend_Serie: ''
    });
    const [showItemsPerVendorButton, setCanSeeItemPerVendor] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value, checked } = e.target;

        setVendorData(prevdata => ({
            ...prevdata,
            [name]: name === 'vend_Enable' ? checked : value,
        }))
    }

    const handleEditClick = (vendor) => {
        setVendorData({
            vend_Id: vendor.id,
            vend_TradeName: vendor.name,
            vend_LegalName: vendor.legalName,
            vend_RFC: vendor.rfc,
            vend_Address: vendor.legalAddress,
            vend_Enable: vendor.enable,
            vend_Serie: vendor.serie,
            vend_Enable: Boolean(vendor.enable)
        });

        setCanSeeItemPerVendor(true);
    }

    const handleCancelClick = async (vendor) => {
        messages.showLoadingMessage();
        const _vendor = JSON.stringify({ vend_Id: vendor.id })
        const ok = await Delete(_vendor);
        if (ok) window.location.reload();
        messages.hideLoadingMessage();
    }

    const handleSaveClick = async () => {
        messages.showLoadingMessage();
        const vendor = {
            id: vendorData.vend_Id,
            name: vendorData.vend_TradeName,
            legalName: vendorData.vend_LegalName,
            legalAddress: vendorData.vend_Address,
            enable: Boolean(vendorData.vend_Enable),
            RFC: vendorData.vend_RFC,
            serie: vendorData.vend_Serie,
            GoogleMapsURL: ''
        };
        console.log(JSON.stringify(vendor));
        const ok = await Save(vendor);
        if (ok) window.location.reload();
        messages.hideLoadingMessage();
    }

    useEffect(async () => {
        document.title = 'SIFFS - Proveedores';
        messages.showLoadingMessage();
        const _viewModel = await ViewModel();
        setViewModel(_viewModel);
        console.log(_viewModel);
        messages.hideLoadingMessage();
    }, [])

    return (
        <Layout>
            {htmlElementMessages}
            <>
                <h4>Proveedores</h4>
                <p className='Containerdescripcion'>
                    Modulo para la gestión de proveedores disponibles en sistema
                </p>
                <hr />
                <Row>
                    <Col lg={3} className='vendors-Form'>
                        <Form>
                            <Form.Label>ID</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='ID'
                                name='vend_Id'
                                value={vendorData.vend_Id}
                                onChange={(e) => { handleInputChange(e); }}
                            />
                            <Form.Label>Nombre Comercial</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Nombre Comercial'
                                name='vend_TradeName'
                                value={vendorData.vend_TradeName}
                                onChange={(e) => { handleInputChange(e); }}
                            />
                            <Form.Label>Razón Social</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Razón Social'
                                name='vend_LegalName'
                                value={vendorData.vend_LegalName}
                                onChange={(e) => { handleInputChange(e); }}
                            />
                            <Form.Label>Serie</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Serie'
                                name='vend_Serie'
                                value={vendorData.vend_Serie}
                                onChange={(e) => { handleInputChange(e); }}
                            />
                            <Form.Label>RFC</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='RFC'
                                name='vend_RFC'
                                value={vendorData.vend_RFC}
                                onChange={(e) => { handleInputChange(e); }}
                            />
                            <Form.Label>Domicilio Fiscal</Form.Label>
                            <Form.Control
                                type='text'
                                placeholder='Domicilio fiscal'
                                name='vend_Address'
                                value={vendorData.vend_Address}
                                onChange={(e) => { handleInputChange(e); }}
                            />
                            <Form.Check
                                type='checkbox'
                                id='vendor-Form-check-isAvaible'
                                label={'Habilitado'}
                                name='vend_Enable'
                                checked={vendorData.vend_Enable}
                                onChange={(e) => { handleInputChange(e); }}
                            />

                            <br />
                            <Button
                                variant='success'
                                onClick={() => {
                                    messages.showConfirmMessage(() => { return () => { handleSaveClick(); } })
                                }}
                            >
                                Guardar
                            </Button>
                            &nbsp;&nbsp;
                            <Button
                                variant='secondary'
                                onClick={() => {
                                    setVendorData({
                                        vend_Id: 0,
                                        vend_TradeName: '',
                                        vend_LegalName: '',
                                        vend_Address: '',
                                        vend_Enable: false,
                                        vend_RFC: ''
                                    });
                                }}
                            >
                                Nuevo
                            </Button>
                            &nbsp;&nbsp;
                            {
                                (showItemsPerVendorButton && vendorData.vend_Id > 0) && (
                                    <Button onClick={() => { navigate(`/vendors/items/list/${vendorData.vend_Id}`, { replace: true }); }}>
                                        Productos
                                    </Button>
                                )
                            }
                        </Form>
                    </Col>
                    <Col className='vendors-Tabele-Container'>
                        <Form.Control type='text' placeholder='Buscar' className='SearchVegetable' />
                        <img
                            className='btn-Search'
                            src={icono_buscar}
                            width={25}
                            height={25}
                            alt='botton search'
                        />
                        <Table
                            hover
                            striped
                            className='GenericTable'
                        >
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>Razón Social</th>
                                    <th>Activo</th>
                                    <th>Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    viewModel?.vendors?.map((vendor, index) => (
                                        <tr
                                            key={index}
                                            onClick={() => { handleEditClick(vendor); }}
                                        >
                                            <td className='text-center'>{vendor.id}</td>
                                            <td className='text-center'>{vendor.name}</td>
                                            <td className='text-center'>{vendor.legalName}</td>
                                            <td className='table-col-Type-check'>
                                                <Form.Check
                                                    defaultChecked={vendor.enable}
                                                    disabled
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <span>
                                                    <img
                                                        src={icono_editar}
                                                        alt='icon_edit'
                                                        onClick={() => { handleEditClick(vendor); }}
                                                    />
                                                    &nbsp;|&nbsp;
                                                    <img
                                                        src={icono_cancelar}
                                                        alt=' icon_delete'
                                                        onClick={() => {
                                                            messages.showConfirmMessage(() => { return () => { handleCancelClick(vendor); } })
                                                        }}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default Vendors;