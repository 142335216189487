import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import Layout from 'components/General/layout';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import './kardex.css';
import { excel_icon } from 'Assets/Images/images';
import UseMessages from 'components/Mensajes/messages_section';
import { KXViewModel, getKardexData } from 'Data/Inventories/InventoriesData';

const Kardex = () => {
    const messages = UseMessages();
    const messagesHTMLElement = messages.messagesHTMLElement;
    const [filter, setfilter] = useState({
        itemId: 0,
        vendorId: 0,
        inventoryId: 0,
        warehouseId: 0,
        dateFrom: new Date().toLocaleDateString("en-CA"),
        dateTo: new Date().toLocaleDateString("en-CA")
    })
    const [kardex_data, setKardexData] = useState([]);
    const [viewModel, setViewModel] = useState(null);
    const [text_info, setTextInfo] = useState(false);

    const handleOnChangeInput = (e) => {
        const { name, value } = e.target;

        setfilter(lastData => ({
            ...lastData,
            [name]: value
        }))
    }

    const FetchKardexData = async () => {
        messages.showLoadingMessage();
        setKardexData([]);
        const data = await getKardexData(filter);
        console.log(data);
        if (data) {
            setKardexData(data);
        }
        else {
            setTextInfo(true);
            setTimeout(() => {
                setTextInfo(false);
            }, 3000);
        }
        messages.hideLoadingMessage();
    }

    useEffect(async () => {
        document.title = 'SIFFS - Kardex';
        messages.showLoadingMessage();
        const _viewModel = await KXViewModel();
        setViewModel(_viewModel);
        messages.hideLoadingMessage();
    }, [])

    return (
        <Layout>
            <>
                {messagesHTMLElement}
                <h4>Kardex</h4>
                <p className='Containerdescripcion'>
                    En esta seccion puede consultar todos los movimientos de inventario y sus saldos historicos.
                </p>
                <hr />
                <Row>
                    <h5 className='Containerdescripcion'>Filtros de busqueda</h5>
                </Row>
                <Row className='w-75 m-auto'>
                    <Col lg={6}>
                        <Form.Label>Producto</Form.Label>
                        <Form.Select
                            className=''
                            name='itemId'
                            value={filter.itemId}
                            onChange={e => { handleOnChangeInput(e); }}
                        >
                            <option value={0}>Seleccione producto</option>
                            {
                                viewModel?.items?.map(item => (
                                    <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col lg={3}>
                        <Form.Label>(Fecha registro) Desde:</Form.Label>
                        <Form.Control
                            type='date'
                            name='dateFrom'
                            value={filter.dateFrom}
                            onChange={e => { handleOnChangeInput(e); }}
                        />
                    </Col>
                    <Col lg={3}>
                        <Form.Label>(Fecha registro) Hasta:</Form.Label>
                        <Form.Control
                            type='date'
                            name='dateTo'
                            value={filter.dateTo}
                            onChange={e => { handleOnChangeInput(e); }}
                        />
                    </Col>
                </Row>
                <Row className='w-75 m-auto mt-3'>
                    <Col lg={4}>
                        <Form.Label>Proveedor</Form.Label>
                        <Form.Select
                            name='vendorId'
                            value={filter.vendorId}
                            onChange={e => { handleOnChangeInput(e); }}
                        >
                            <option value={0}>Seleccione proveedor</option>
                            {
                                viewModel?.vendors?.map((vendor) => (
                                    <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col lg={4}>
                        <Form.Label>Inventario</Form.Label>
                        <Form.Select
                            name='inventoryId'
                            value={filter.inventoryId}
                            onChange={e => { handleOnChangeInput(e); }}
                        >
                            <option value={0}>Seleccione inventario</option>
                            {
                                viewModel?.inventories?.map(inventory => (
                                    <option key={inventory.id} value={inventory.id}>{inventory.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col lg={4}>
                        <Form.Label>Almacen</Form.Label>
                        <Form.Select
                            name='warehouseId'
                            value={filter.warehouseId}
                            onChange={e => { handleOnChangeInput(e); }}
                        >
                            <option value={0}>Seleccione almacen</option>
                            {
                                viewModel?.warehouses?.map(warehouse => (
                                    <option key={warehouse.id} value={warehouse.id}>{warehouse.name}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                </Row>
                <Row className='justify-content-end'>
                    <Button
                        variant='secondary mt-3 mx-5'
                        style={{ width: '120px' }}
                        onClick={FetchKardexData}
                    >
                        Buscar
                    </Button>
                </Row>
                <hr />
                <Row>
                    <Col md={12}>
                        {kardex_data.length > 0 && (
                            <ReactHTMLTableToExcel
                                id="botonExportar"
                                table="kardex"
                                className='btn btn-success mb-3'
                                filename={`kardex-table`}
                                sheet="kardex"
                                buttonText={(
                                    <>
                                        <img src={excel_icon} width={30} height={30} className='mx-2 inline-block' />
                                        <span>Exportar</span>
                                    </>
                                )}
                            />
                        )}
                    </Col>
                </Row>

                <Row> {/* Kardex table */}
                    <Col lg={12}>

                        <Table
                            hover
                            className='GenericTable kardex-table overedflow'
                            id='kardex'
                        >
                            <thead className='text-center align-middle'>
                                <tr>
                                    <th>Folio</th>
                                    <th>Almacen</th>
                                    <th>Inventario</th>
                                    <th>Proveedor</th>
                                    <th>Producto</th>
                                    <th>Fecha movimiento</th>
                                    <th>Fecha registro</th>
                                    <th>Entrada cantidad</th>
                                    <th>Entrada Costo</th>
                                    <th>Entrada Total</th>
                                    <th>Salida Cantidad</th>
                                    <th>Salida Costo</th>
                                    <th>Salida Total</th>
                                    <th>Saldo Cantidad</th>
                                    <th>Saldo Costo</th>
                                    <th>Saldo Total</th>
                                </tr>
                            </thead>
                            <tbody className='text-center align-middle'>
                                {
                                    kardex_data?.map(kardex_record => (
                                        <>
                                            <tr key={kardex_record.id}>
                                                <td>{kardex_record.id}</td>
                                                <td>{kardex_record.warehouse.name}</td>
                                                <td>{kardex_record.inventory.name}</td>
                                                <td>{kardex_record.vendor.name}</td>
                                                <td>{kardex_record.item.name}</td>
                                                <td className='text-center'>{new Date(kardex_record.date).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                                <td className='text-center'>{new Date(kardex_record.create_At).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</td>
                                                <td className='col-e'>{kardex_record.quantityReceived === 0 ? null : (kardex_record.quantityReceived)}</td>
                                                <td className='col-e'>{kardex_record.costReceived === 0 && kardex_record.quantityReceived === 0 ? null : ('$' + kardex_record.costReceived)}</td>
                                                <td className='col-e'>{kardex_record.totalReceived === 0 && kardex_record.quantityReceived === 0 ? null : ('$' + kardex_record.totalReceived)}</td>
                                                <td className='col-s'>{kardex_record.quantityEgress === 0 ? null : kardex_record.quantityEgress}</td>
                                                <td className='col-s'>{kardex_record.costEgress === 0 && kardex_record.quantityEgress === 0 ? null : ('$' + kardex_record.costEgress)}</td>
                                                <td className='col-s'>{kardex_record.totalEgress === 0 && kardex_record.quantityEgress === 0 ? null : ('$' + kardex_record.totalEgress)}</td>
                                                <td className='col-b'>{kardex_record.costEgress === 0 && kardex_record.quantityReceived === 0 ? null : kardex_record.quantityBalance}</td>
                                                <td className='col-b'>{kardex_record.costEgress === 0 && kardex_record.quantityReceived === 0 ? null : ('$' + kardex_record.costBalance)}</td>
                                                <td className='col-b'>{kardex_record.totalEgress === 0 && kardex_record.quantityReceived === 0 ? kardex_record.quantityBalance < 0 ? ('-$' + (kardex_record.totalBalance * -1)) : null : ('$' + kardex_record.totalBalance)}</td>
                                            </tr>
                                        </>
                                    ))
                                }
                            </tbody>
                        </Table>
                        {text_info && (<p className='kardex-noinfo-text text-danger text-center'>Sin información</p>)}
                    </Col>
                </Row>
            </>
        </Layout>
    );
}

export default Kardex;